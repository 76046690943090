const mediaTypes: Record<string, 'image' | 'video'> = {
  jpg: 'image',
  jpeg: 'image',
  png: 'image',
  gif: 'image',
  webp: 'image',
  svg: 'image',
  mp4: 'video',
  avi: 'video',
  mov: 'video',
  wmv: 'video',
  webm: 'video'
}

const getMediaType = (url?: string): 'invalid' | 'image' | 'video' => {
  if (!url) return 'invalid'

  const ext = url.split('.').pop()?.toLowerCase()

  if (!ext) return 'invalid'

  return mediaTypes[ext] || 'invalid'
}

export { getMediaType }

import { InputHTMLAttributes, useMemo } from 'react'
import { SearchVariantProps, search } from './search.variants'

export type useSearchProps = SearchVariantProps & {
  className?: string
  label?: string | null
} & InputHTMLAttributes<HTMLInputElement>

export const useSearch = (props: useSearchProps) => {
  const {
    className = '',
    theme = 'dark',
    label = 'Search features...',
    id,
    name,
    placeholder,
    ...componentProps
  } = props

  const fieldId = id || name

  const styles = useMemo(() => search({ theme }), [theme])

  const placeHolder = placeholder ?? label ?? undefined

  return {
    className,
    fieldId,
    label,
    styles,
    placeHolder,
    componentProps: {
      ...componentProps,
      name
    }
  }
}

'use client'

import { Button } from '../button'
import { Icon } from '../icon'
import { Text } from '../text'
import { useIconCard, useIconCardProps } from './use-icon-card'
import { VideoCard } from '../video-card'

export type IconCardProps = useIconCardProps

const IconCard = (props: IconCardProps) => {
  const {
    className,
    sectionName,
    styles,
    theme,
    data: { icon, title, description, link, videoUrl, videoCover },
    buttonColor,
    isAppearanceInline,
    isAppearanceInlineVideo
  } = useIconCard(props)

  const titleSize = isAppearanceInline ? 'h4' : 'subtitle'
  const descriptionSize = isAppearanceInline ? 'h6' : undefined
  const showButtonOutline = isAppearanceInline || isAppearanceInlineVideo

  const CTA = () => (
    <Button
      sectionName={`${sectionName}_${title}`}
      href={link.url}
      title={link.text}
      newTab={link.newTab}
      appearance={showButtonOutline ? 'outline' : 'text'}
      color={showButtonOutline ? 'gray' : buttonColor}
      leftIcon={showButtonOutline ? 'arrow_right' : undefined}
      rounded={showButtonOutline}
    >
      {showButtonOutline ? undefined : link.text}
    </Button>
  )

  return (
    <article
      data-component-name="icon-card"
      className={styles.base({ className })}
    >
      {isAppearanceInlineVideo && !!videoUrl && (
        <div className={styles.videoWrapper()}>
          <VideoCard
            appearance={'default'}
            title={title}
            url={videoUrl}
            cover={videoCover}
          />
        </div>
      )}
      <header className={styles.header()}>
        {!!icon && <Icon name={icon?.name} className={styles.icon()} />}
        {!!title && (
          <Text
            tag="h3"
            size={titleSize}
            theme={theme}
            className={styles.title()}
            weight="medium"
          >
            {title}
          </Text>
        )}
        {!!description && (
          <Text
            size={descriptionSize}
            theme={theme}
            className={styles.description()}
            weight="medium"
          >
            {description}
          </Text>
        )}
        {isAppearanceInlineVideo && !!link && <CTA />}
      </header>
      {!!link && !isAppearanceInlineVideo && (
        <footer className={styles.footer()}>
          <CTA />
        </footer>
      )}
    </article>
  )
}

export default IconCard

import { Locale } from '@/config/locales'

export const formatDateToString = (
  value: string,
  locale: Locale = 'en',
  opts = {}
) => {
  const date = new Date(Date.parse(value))
  const utcDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  )

  const formatter = new Intl.DateTimeFormat(locale, {
    ...opts,
    timeZone: 'UTC'
  })

  return formatter.format(utcDate)
}

'use client'

import { Children, isValidElement } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, EffectFade } from 'swiper/modules'
import { SwiperNav } from '../swiper-nav'
import { gallery } from './gallery.variants'
import 'swiper/css/navigation'
import 'swiper/css/effect-fade'

export type GalleryProps = {
  children?: React.ReactNode
}

const Gallery = ({ children }: GalleryProps) => {
  const id = 'gallery'
  const styles = gallery()

  const renderGallery = () => {
    return Children.map(children, (child, i) => {
      if (!isValidElement(child)) {
        return null
      }

      return (
        <SwiperSlide key={`gallery-item-${i}`}>
          <figure className={styles.figure()}>{child}</figure>
        </SwiperSlide>
      )
    })
  }

  return (
    <Swiper
      className={styles.base()}
      effect="fade"
      modules={[Navigation, EffectFade]}
      slidesPerView={1}
      simulateTouch={false}
      navigation={{
        prevEl: `.prev-carousel-${id}`,
        nextEl: `.next-carousel-${id}`
      }}
    >
      {renderGallery()}
      <SwiperNav id={id} theme="light" className={styles.nav()} />
    </Swiper>
  )
}

export default Gallery

import { tv, VariantProps } from '@/utils/tv'

export const artDirection = tv({
  slots: {
    mobile: 'select-none',
    desktop: 'select-none'
  },
  variants: {
    hiddenOn: {
      sm: '',
      md: '',
      lg: '',
      xl: ''
    },
    hasMobileMedia: {
      true: '',
      false: ''
    }
  },
  defaultVariants: {
    hasMobileMedia: true,
    hiddenOn: 'sm'
  },
  compoundVariants: [
    {
      hiddenOn: 'sm',
      hasMobileMedia: true,
      className: {
        mobile: 'block sm:hidden',
        desktop: 'hidden sm:block'
      }
    },
    {
      hiddenOn: 'md',
      hasMobileMedia: true,
      className: {
        mobile: 'block md:hidden',
        desktop: 'hidden md:block'
      }
    },
    {
      hiddenOn: 'lg',
      hasMobileMedia: true,
      className: {
        mobile: 'block lg:hidden',
        desktop: 'hidden lg:block'
      }
    },
    {
      hiddenOn: 'xl',
      hasMobileMedia: true,
      className: {
        mobile: 'block xl:hidden',
        desktop: 'hidden xl:block'
      }
    }
  ]
})

export type ArtDirectionVariantProps = Omit<
  VariantProps<typeof artDirection>,
  'hasMobileMedia'
>

'use client'

import { useContext, useMemo } from 'react'
import { motion } from 'framer-motion'
import { Theme } from '@/types'
import { Text } from '../text'
import { Icon } from '../icon'
import { AccordionContext } from './accordion.context'
import { accordionItem } from './accordion.variants'

export type AccordionItemProps = {
  accordionId: string
  theme: Theme
  title: string
  children: React.ReactNode
}

const AccordionItem = (props: AccordionItemProps) => {
  const { theme = 'dark', accordionId, title, children } = props
  const { isExpanded, toggle } = useContext(AccordionContext)

  const expanded = isExpanded(accordionId)

  const styles = useMemo(
    () => accordionItem({ theme, isExpanded: expanded }),
    [theme, expanded]
  )

  return (
    <div id={`accordion-${accordionId}`} className={styles.base()}>
      <div>
        <Text
          tag="h4"
          size="description"
          weight="medium"
          id={`heading-${accordionId}`}
          className={styles.title()}
        >
          <button
            type="button"
            className={styles.button()}
            aria-expanded={expanded}
            aria-controls={`collapse-${accordionId}`}
            onClick={(e) => toggle(e, accordionId)}
            tabIndex={0}
          >
            {title}
            <Icon name="chevron_down_2" className={styles.icon()} />
          </button>
        </Text>
      </div>
      <div
        id={`collapse-${accordionId}`}
        className={styles.content()}
        aria-labelledby={`heading-${accordionId}`}
        role="region"
      >
        <motion.div
          initial="collapsed"
          animate={expanded ? 'open' : 'collapsed'}
          exit="collapsed"
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { opacity: 0, height: 0 }
          }}
        >
          {children}
        </motion.div>
      </div>
    </div>
  )
}

export default AccordionItem

import { BlogCategory } from '@/types'
import { strapiFetch } from '@/services/client'
import { Locale } from '@/config/locales'
import { createLocalizableTag } from '@/utils/locale'

const getAllBlogCategories = async (locale: Locale, slug?: string) => {
  const params: Record<string, string | number> = {
    _locale: locale,
    _limit: -1
  }

  if (slug) {
    params.slug = slug
    params._limit = 1
  }

  const tag = createLocalizableTag('blog-categories', locale)

  return await strapiFetch<BlogCategory[]>({
    path: `blog-categories`,
    params,
    options: {
      next: {
        tags: [tag]
      }
    }
  })
}

export default getAllBlogCategories

import { tv, VariantProps } from '@/utils/tv'

export const columns2ImageAndContent = tv({
  slots: {
    base: ['relative', 'w-full h-full', 'overflow-hidden'],
    wrapper: [],
    bullet: [
      'flex overflow-hidden',
      '!w-2.5 !h-2.5',
      '!bg-gray-60',
      'rounded-full',
      '-indent-20'
    ],
    bullets: [
      'w-full',
      'flex md:hidden justify-center',
      'absolute z-40 !inset-x-0 !bottom-4'
    ],
    nav: [
      'hidden md:flex',
      'absolute z-40 !inset-x-0',
      'md:bottom-8 mx-auto',
      'max-w-7xl',
      'px-6 lg:px-8',
      'rtl:md:flex-row-reverse rtl:md:justify-end'
    ],
    card: ['flex h-auto'],
    cardCaption: ['min-h-[390px] sm:min-h-[340px] md:min-h-[auto]'],
    innerCard: []
  },
  variants: {
    theme: {
      dark: {
        base: 'bg-black'
      },
      light: {
        base: 'bg-gray-10'
      }
    },
    appearance: {
      default: '',
      pro: ''
    }
  },
  compoundVariants: [
    {
      appearance: 'pro',
      className: {
        base: 'bg-black'
      }
    }
  ],
  defaultVariants: {
    theme: 'light'
  }
})

export const columns2ImageAndContentNav = tv({
  slots: {
    base: ['hidden lg:block', 'mt-8 mb-8', 'sticky'],
    list: ['flex justify-center gap-4'],
    bubble: [
      'absolute left-0 bottom-0',
      'h-12 border border-white rounded-[50vh]',
      'origin-[left_center]'
    ],
    anchor: [
      'flex justify-center items-center',
      'font-medium',
      'h-12 px-5',
      'text-white',
      'text-display-12 lg:text-display-14',
      'whitespace-nowrap',
      'cursor-pointer'
    ]
  }
})

export type Columns2ImageAndContentVariantProps = VariantProps<
  typeof columns2ImageAndContent
>

export type Columns2ImageAndContentVariantReturnProps = ReturnType<
  typeof columns2ImageAndContent
>

import { tv, VariantProps } from '@/utils/tv'

export const tableOfContents = tv({
  slots: {
    base: [
      'overflow-hidden hide-scrollbar',
      "after:w-full after:h-[70%] after:absolute after:bottom-0 after:content-[''] after:bg-gradient-to-t after:to-transparent after:pointer-events-none",
      'transition-transform duration-300',
      'bg-white',
      'fixed',
      'bottom-0 left-0',
      'mobile:rounded-t-[35px]',
      'w-full',
      'color-black',
      'p-8 pt-4',
      'after:from-white after:h-[150px] after:w-[100%]',
      'z-[11]',
      'translate-y-[65dvh]',
      'lg:bg-transparent',
      'lg:p-0',
      'lg:sticky lg:top-[180px] lg:mb-[200px]',
      'lg:after:from-black lg:after:h-[300px] lg:after:w-[300px]',
      'lg:min-w-64',
      'lg:translate-y-[0]'
    ],
    nav: [],
    btn: [
      'table mx-auto mb-[30px]',
      'lg:mx-0 lg:mb-2',
      'desktop:mb-[30px]',
      'desktop:pointer-events-none'
    ],
    title: [
      'text-black text-center text-4/[1.5] font-bold',
      'lg:text-[1.2rem]/[1] lg:text-left lg:text-white'
    ],
    subtitle: ['text-gray-60 text-1/[1.5] text-display-12 desktop:hidden'],
    list: [
      'hide-scrollbar overflow-y-scroll',
      'w-[100%] min-h-[25dvh] max-h-[60dvh]',
      'text-[1rem]/[1.5] font-medium',
      'desktop:pb-[150px]',
      'lg:max-w-64 lg:max-h-[70dvh]'
    ],
    listItem: ['mb-4', 'last:mb-[150px]'],
    link: [
      'no-underline text-black transition-opacity hover:opacity-70 lg:text-white'
    ],
    close: [
      'hidden',
      'justify-center items-center',
      'absolute top-[12px] right-[25px] z-50 w-8 h-8',
      'text-black',
      'transition-colors duration-300',
      'rounded-full outline-none lg:focus:outline-offset-0 lg:focus:outline-acqua-500'
    ],
    icon: ['w-2.5 h-2.5']
  },
  variants: {
    open: {
      true: {
        base: ['translate-y-[0]'],
        close: ['flex']
      }
    }
  }
})

export type TableOfContentsVariantProps = VariantProps<typeof tableOfContents>

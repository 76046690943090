'use client'

import { motion } from 'framer-motion'
import { useVideo, useVideoProps } from './use-video'

export type VideoProps = useVideoProps

const Video = (props: VideoProps) => {
  const {
    ref,
    className,
    containerClassName,
    styles,
    handlers,
    url,
    mime,
    componentProps
  } = useVideo(props)

  return (
    <motion.div
      className={styles.base({ className: containerClassName })}
      onViewportEnter={() => handlers.start()}
      onViewportLeave={() => handlers.pause()}
    >
      <video
        ref={ref}
        className={styles.video({ className })}
        {...componentProps}
      >
        <source src={url} type={mime} />
      </video>
    </motion.div>
  )
}

export default Video

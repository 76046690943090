import { tv, VariantProps } from '@/utils/tv'

export const searchList = tv({
  slots: {
    base: ['relative', 'py-10 lg:py-20', 'overflow-hidden'],
    header: ['relative', 'mb-20', 'overflow-hidden', 'border-b'],
    headerWrapper: [
      'relative z-20',
      'pb-10 py-20 md:pb-20',
      'flex flex-col items-center',
      'text-center',
      'max-w-7xl',
      'mx-auto px-6 lg:px-8'
    ],
    preTitle: ['mb-2'],
    title: [],
    description: ['mt-2'],
    search: ['mt-10 max-w-md'],
    figure: ['absolute z-0', 'w-full h-full'],
    image: ['object-contain object-bottom', 'w-full h-full'],
    container: ['max-w-7xl', 'mx-auto px-6 lg:px-8'],
    content: ['grid grid-cols-1 gap-10']
  },
  variants: {
    theme: {
      dark: {
        base: ['bg-black'],
        header: ['border-gray-200'],
        preTitle: ['text-acqua-500'],
        description: ['text-gray-40']
      },
      light: {
        base: ['bg-gray-5'],
        header: ['border-gray-20'],
        preTitle: ['text-acqua-900'],
        description: ['text-gray-60']
      }
    },
    columns: {
      1: {
        content: []
      },
      2: {
        content: ['md:grid-cols-2']
      },
      3: {
        content: ['md:grid-cols-2 lg:grid-cols-3']
      },
      4: {
        content: ['md:grid-cols-2 lg:grid-cols-4']
      },
      5: {
        content: ['md:grid-cols-3 lg:grid-cols-5']
      },
      6: {
        content: ['md:grid-cols-3 lg:grid-cols-6']
      }
    }
  },
  defaultVariants: {
    theme: 'dark',
    columns: 3
  }
})

export type SearchListVariantProps = VariantProps<typeof searchList>

'use client'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { TeamCarousel } from '@/types'
import { SwiperNav } from '@/components/ui/swiper-nav'
import { TeamCard } from '@/components/ui/team-card'

export type TeamCarouselSwiperProps = {
  className?: string
  navClassName?: string
  sectionName: string
  data: TeamCarousel
}

const TeamCarouselSwiper = ({
  className = '',
  sectionName,
  navClassName = '',
  data
}: TeamCarouselSwiperProps) => {
  const { id, theme, team = [] } = data

  return (
    <Swiper
      className={className}
      modules={[Navigation]}
      spaceBetween={40}
      slidesPerView={3}
      initialSlide={0}
      simulateTouch={false}
      navigation={{
        prevEl: `.prev-carousel-${id}`,
        nextEl: `.next-carousel-${id}`
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        520: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        993: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      }}
    >
      {team.map((member) => (
        <SwiperSlide key={`team-card-${member.id}`} className="!h-auto">
          <TeamCard theme={theme} sectionName={sectionName} data={member} />
        </SwiperSlide>
      ))}
      {team.length > 3 && (
        <SwiperNav id={id} className={navClassName} theme={theme} />
      )}
    </Swiper>
  )
}

export default TeamCarouselSwiper

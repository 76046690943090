import { Suspense } from 'react'
import { Text } from '@/components/ui/text'
import { Avatar } from '@/components/ui/avatar'
import { useAuthor, useAuthorProps } from './use-author'
import AuthorDate from './author.date'

export type AuthorProps = useAuthorProps

const Author = (props: AuthorProps) => {
  const {
    className,
    styles,
    theme,
    sizeAvatar,
    sizeName,
    writer,
    date,
    locale,
    readTime,
    showBio
  } = useAuthor(props)

  return (
    <div className={styles.base({ className })}>
      <div className={styles.details()}>
        {!!writer.picture && (
          <Avatar theme={theme} size={sizeAvatar} image={writer.picture} />
        )}
        <div className={styles.author()}>
          {!!writer.name && (
            <Text
              tag="span"
              size={sizeName}
              weight="medium"
              theme={theme}
              className={styles.name()}
            >
              {writer.name}
            </Text>
          )}
          {!!date && (
            <Suspense>
              <div className={styles.date()}>
                <AuthorDate date={date} locale={locale} />
                {!!readTime && (
                  <>
                    <Text
                      tag="span"
                      size="tinyBody"
                      weight="medium"
                      aria-hidden="true"
                    >
                      ·
                    </Text>
                    <Text tag="span" size="tinyBody" weight="medium">
                      {readTime}
                    </Text>
                  </>
                )}
              </div>
            </Suspense>
          )}
        </div>
      </div>
      {showBio && writer?.bio && (
        <div className={styles.bio()}>
          <Text weight="medium" size="description">
            {writer.bio}
          </Text>
        </div>
      )}
    </div>
  )
}

export default Author

import { useMemo } from 'react'
import { ImageProps } from 'next/image'
import { getMediaType } from '@/utils/media'
import {
  ArtDirectionVariantProps,
  artDirection
} from './art-direction.variants'

export type ArtDirectionMedia = {
  src?: string
  alt?: string
  width?: number
  height?: number
}

export type useArtDirectionProps = ArtDirectionVariantProps & {
  className?: string
  containerClassName?: string
  desktop?: ArtDirectionMedia
  mobile?: ArtDirectionMedia
} & Omit<ImageProps, 'src' | 'alt'>

export const useArtDirection = (props: useArtDirectionProps) => {
  const {
    containerClassName,
    className = '',
    desktop: desktopProp,
    mobile: mobileProp,
    hiddenOn = 'sm',
    ...componentProps
  } = props

  const styles = useMemo(
    () => artDirection({ hiddenOn, hasMobileMedia: !!mobileProp }),
    [hiddenOn, mobileProp]
  )

  const getMedia = (media?: ArtDirectionMedia) => {
    if (!media) return

    const mediaType = getMediaType(media.src as string | undefined)

    if (mediaType !== 'image') return

    return media
  }

  const desktop = getMedia(desktopProp)
  const mobile = getMedia(mobileProp)
  const shouldRender = !!desktop || !!mobile

  return {
    containerClassName,
    className,
    desktop,
    mobile,
    shouldRender,
    styles,
    ...componentProps
  }
}

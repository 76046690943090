'use client'

import { Text } from '@/components/ui/text'
import { Icon } from '@/components/ui/icon'
import {
  useTableOfContents,
  useTableOfContentsProps
} from './use-table-of-contents'
import NextLink from 'next/link'
import { type TocItem } from '../../markdown/plugins/toc-headings'
import useDisableScroll from '@/hooks/use-disable-scroll'

export type TableOfContentsProps = useTableOfContentsProps

const TableOfContents = (props: TableOfContentsProps) => {
  const {
    theme,
    styles,
    tocItems,
    menuRef,
    title,
    subtitle,
    isTocOpen,
    showToc,
    linkClick
  } = useTableOfContents(props)

  useDisableScroll(isTocOpen, menuRef)

  const items = tocItems.map((item: TocItem) => (
    <li key={item.url} className={styles.listItem()}>
      <NextLink
        href={item.url}
        onClick={linkClick}
        title={item.value}
        className={styles.link()}
      >
        {item.value}
      </NextLink>
    </li>
  ))

  return (
    <div className={styles.base()}>
      <button className={styles.btn()} onClick={() => showToc(true)}>
        <Text
          theme={theme}
          tag="h3"
          size="subtitle"
          weight="medium"
          className={styles.title()}
        >
          {title}
        </Text>
        {!isTocOpen && (
          <Text
            theme={theme}
            tag="h4"
            size="subtitle"
            weight="normal"
            className={styles.subtitle()}
          >
            {subtitle}
          </Text>
        )}
      </button>
      <button
        onClick={() => showToc(false)}
        autoFocus
        className={styles.close()}
      >
        <Icon name="close" className={styles.icon()} />
      </button>
      <nav className={styles.nav()} ref={menuRef}>
        <ul className={styles.list()}>{items}</ul>
      </nav>
    </div>
  )
}

export default TableOfContents

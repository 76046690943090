import { tv, VariantProps } from '@/utils/tv'

export const author = tv({
  slots: {
    base: ['flex flex-col gap-5'],
    details: ['flex gap-4'],
    author: ['flex flex-col justify-center'],
    name: [],
    date: ['flex flex-wrap gap-x-2'],
    bio: []
  },
  variants: {
    theme: {
      dark: {
        base: ['border-white'],
        date: ['text-gray-40'],
        bio: ['text-gray-40']
      },
      light: {
        base: ['border-black'],
        date: ['text-gray-60'],
        bio: ['text-gray-60']
      }
    }
  },
  defaultVariants: {
    theme: 'dark'
  }
})

export type AuthorVariantProps = VariantProps<typeof author>

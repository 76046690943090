import getAllBlogCategories from './categories'
import getAllBlogPosts from './posts'
import getBlogPost from './post'
import getAllBlogPostsPaths from './paths'

export {
  getAllBlogCategories,
  getBlogPost,
  getAllBlogPosts,
  getAllBlogPostsPaths
}

'use client'

import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { Hero } from '@/types'
import { getMediaType } from '@/utils/media'
import { ArtDirection } from '@/components/ui/art-direction'
import { Video } from '@/components/ui/video'

export type HeroHighlightProps = {
  align: Hero['align']
  imageHighlight: Hero['imageHighlight']
  highlightType: 'video' | 'image' | 'invalid'
}

const HeroHighlight = ({
  align,
  highlightType,
  imageHighlight
}: HeroHighlightProps) => {
  const [showHighlight, setShowHighlight] = useState(false)
  const { width } = useWindowSize()

  useEffect(() => {
    if (highlightType === 'image' && width < 768) {
      setShowHighlight(false)
    } else {
      setShowHighlight(true)
    }
  }, [highlightType, showHighlight, width])

  if (!imageHighlight || align !== 'left' || !showHighlight) {
    return null
  }

  return (
    <div>
      {highlightType === 'image' && (
        <ArtDirection
          containerClassName="sm:max-w-sm md:max-w-full sm:mx-auto mt-12 md:mt-0"
          priority
          desktop={{
            src: imageHighlight?.url,
            alt: imageHighlight?.alternativeText,
            width: imageHighlight?.width,
            height: imageHighlight?.height
          }}
        />
      )}
      {highlightType === 'video' && (
        <Video
          size="full"
          src={imageHighlight?.url}
          mime={imageHighlight?.mime}
          autoPlay
        />
      )}
    </div>
  )
}

export default HeroHighlight

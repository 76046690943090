import { tv, VariantProps } from '@/utils/tv'

export const youtubeVideo = tv({
  slots: {
    base: ['rounded-xl'],
    video: ['aspect-w-16 aspect-h-9']
  }
})

export type YoutubeVideoVariantProps = VariantProps<typeof youtubeVideo>

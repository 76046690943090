'use client'

import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { motion, useScroll, useTransform } from 'framer-motion'
import { HeroCenteredParallax } from '@/types'
import { Video } from '@/components/ui/video'
import { heroCenteredParallaxVideos } from './hero-centered-parallax.variants'

export type HeroCenteredParallaxVideosProps = {
  topVideos?: HeroCenteredParallax['videos']
  bottomVideos?: HeroCenteredParallax['videos']
}

const SCREEN_WIDTH_TO_SHOW_VIDEOS = 768

const HeroCenteredParallaxVideos = ({
  topVideos = [],
  bottomVideos = []
}: HeroCenteredParallaxVideosProps) => {
  const [showVideos, setShowVideos] = useState(false)
  const { width } = useWindowSize()
  const { scrollYProgress } = useScroll()

  const styles = heroCenteredParallaxVideos()

  useEffect(() => {
    if (width >= SCREEN_WIDTH_TO_SHOW_VIDEOS) {
      !showVideos && setShowVideos(true)
    } else {
      showVideos && setShowVideos(false)
    }
  }, [showVideos, width])

  const yTop = useTransform(scrollYProgress, [0, 0.2, 0], [0, -200, 0], {
    clamp: false
  })

  const yBottom = useTransform(scrollYProgress, [0, 0.2, 0], [0, 500, 0], {
    clamp: false
  })

  if (!showVideos) return null

  return (
    <div className={styles.base()}>
      {topVideos.length > 0 && (
        <motion.div className={styles.group()} style={{ y: yTop }}>
          {topVideos.map(({ id, video, poster }) => (
            <Video
              key={`video-top-${id}`}
              className="rounded-xl object-cover"
              containerClassName="w-full"
              size="100"
              src={video.url}
              mime={video.mime}
              poster={poster.url}
            />
          ))}
        </motion.div>
      )}
      {bottomVideos.length > 0 && (
        <motion.div className={styles.group()} style={{ y: yBottom }}>
          {bottomVideos.map(({ id, video, poster }) => (
            <Video
              key={`video-top-${id}`}
              className="rounded-xl object-cover"
              containerClassName="w-full"
              size="100"
              src={video.url}
              mime={video.mime}
              poster={poster.url}
            />
          ))}
        </motion.div>
      )}
    </div>
  )
}

export default HeroCenteredParallaxVideos

import { tv, VariantProps } from '@/utils/tv'

export const search = tv({
  slots: {
    base: ['w-full'],
    label: ['hidden'],
    group: ['relative'],
    iconWrapper: [
      'absolute inset-y-0 right-5',
      'flex items-center',
      'pointer-events-none'
    ],
    icon: ['w-6 h-6'],
    input: [
      'search',
      'form-input',
      'block',
      'w-full h-16',
      'px-6 pr-12',
      'text-display-16',
      'border',
      'rounded-full',
      'appearance-none',
      ''
    ]
  },
  variants: {
    theme: {
      dark: {
        icon: ['text-gray-60'],
        input: [
          'border-gray-100 bg-black/60 text-gray-20 placeholder:text-gray-60',
          'focus:ring-acqua-500 focus:border-acqua-500'
        ]
      },
      light: {
        icon: ['text-gray-80'],
        input: [
          'border-gray-20 bg-white/60 text-gray-80 placeholder:text-gray-60',
          'focus:ring-acqua-900 focus:border-acqua-900'
        ]
      }
    }
  },
  defaultVariants: {
    theme: 'dark'
  }
})

export type SearchVariantProps = VariantProps<typeof search>

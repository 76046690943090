/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import { useEffect, useRef } from 'react'
import { motion, animate, useMotionValue } from 'framer-motion'
import { useWindowSize } from 'react-use'
import { CardContentStickyScroll } from '@/types'
import { useSwiper } from 'swiper/react'
import { columns2ImageAndContentNav } from './columns2-image-and-content.variants'

type Columns2ImageAndContentNavProps = Pick<
  CardContentStickyScroll,
  'theme' | 'cards'
> & {
  currentIndex: number
}

const Columns2ImageAndContentNav = ({
  theme,
  cards,
  currentIndex
}: Columns2ImageAndContentNavProps) => {
  const itemRefs = useRef<HTMLLIElement[]>([])
  const itemWidth = useMotionValue(0)
  const itemPosition = useMotionValue(0)
  const { width } = useWindowSize()
  const swiper = useSwiper()

  const styles = columns2ImageAndContentNav({ theme })

  const updateSlider = (width: number, left: number) => {
    animate(itemWidth, width)
    animate(itemPosition, left)
  }

  useEffect(() => {
    itemRefs.current = itemRefs.current.slice(0, cards.length)
  }, [cards])

  useEffect(() => {
    updateSlider(
      itemRefs.current[currentIndex].offsetWidth,
      itemRefs.current[currentIndex].offsetLeft
    )
  }, [currentIndex, width])

  return (
    <nav className={styles.base()}>
      <ul className={styles.list()}>
        {cards.map(({ id, title }, i) => (
          <li
            key={id}
            ref={(el) => {
              if (el) itemRefs.current[i] = el
            }}
            onClick={() => swiper.slideTo(i)}
          >
            <span className={styles.anchor()}>{title}</span>
          </li>
        ))}
      </ul>
      <motion.div
        className={styles.bubble()}
        style={{
          width: itemWidth,
          x: itemPosition
        }}
      />
    </nav>
  )
}

export default Columns2ImageAndContentNav

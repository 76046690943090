export const extractYouTubeParams = (url: string) => {
  const youtubeIdMatch = url.match(
    /(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/
  )
  const startTimeMatch = url.match(/[?&]t=([^&]+)/)

  return {
    youtubeId: youtubeIdMatch ? youtubeIdMatch[1] : undefined,
    startTime: startTimeMatch ? parseInt(startTimeMatch[1]) : 0
  }
}

import { useMemo, useRef, useState } from 'react'
import { type YouTubeProps, type YouTubePlayer } from 'react-youtube'
import { type Media } from '../../../types/strapi/util'
import {
  YoutubeVideoVariantProps,
  youtubeVideo
} from './youtube-video-full.variants'

export type useYoutubeVideoFullProps = Omit<
  YoutubeVideoVariantProps,
  'playing'
> & {
  className?: string
  cover: Media
  title: string | null
  url: string
}

const pattern =
  /(?:https?:\/\/)?(?:www\.)?(?:m\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?feature=player_embedded&v=))([^\s&?]+)$/

export const useYoutubeVideoFull = (props: useYoutubeVideoFullProps) => {
  const { className = '', rounded = 'lg', cover, title, url } = props

  const [playing, setPlaying] = useState(false)
  const videoRef = useRef<YouTubePlayer>(null)

  const styles = useMemo(
    () => youtubeVideo({ playing, rounded }),
    [playing, rounded]
  )

  const opts: YouTubeProps['opts'] = {
    playerVars: {
      iv_load_policy: 3,
      listType: 'user_uploads',
      rel: 0,
      modestbranding: 1,
      showinfo: 0
    }
  }

  const playVideo = () => {
    const player = videoRef.current

    if (player) {
      player.getInternalPlayer().playVideo()
    }
  }

  const onPlay: YouTubeProps['onPlay'] = () => {
    setPlaying(true)
  }

  const onEnd: YouTubeProps['onEnd' | 'onPause'] = () => {
    setPlaying(false)
  }

  const video = url.match(pattern)

  return {
    videoRef,
    className,
    styles,
    cover,
    title,
    video,
    opts,
    onPlay,
    onEnd,
    playVideo
  }
}

import { BlogPostPath } from '@/types'
import { strapiFetch } from '@/services/client'

const getAllBlogPostsPaths = async () => {
  return await strapiFetch<BlogPostPath[]>({
    path: `blog-posts/routes`
  })
}

export default getAllBlogPostsPaths

'use client'

import { BlogList } from '@/types'
import useInfiniteScroll from '@/hooks/use-infinite-scroll'
import { makePostCardData } from '@/utils/blog'
import { MediaCard } from '@/components/ui/media-card'
import { Button } from '@/components/ui/button'
import { blogGrid } from './blog-list.variants'

type BlogListGridProps = {
  sectionName: string
} & Pick<BlogList, 'theme' | 'buttonLabel' | 'posts' | 'perLoad'>

const BlogListGrid = ({
  sectionName,
  theme,
  posts,
  perLoad,
  buttonLabel
}: BlogListGridProps) => {
  const styles = blogGrid()
  const items = useInfiniteScroll(posts, perLoad)
  const { loading, hasMoreItems, loadedItems, getMoreItems } = items

  const [firstCard, ...cards] = loadedItems.map((post) =>
    makePostCardData(post)
  )

  return (
    <div className={styles.base()}>
      <div className={styles.content()}>
        <div className={styles.highlight()}>
          {firstCard && (
            <MediaCard
              theme={theme}
              sectionName={sectionName}
              layout="horizontal"
              data={firstCard}
            />
          )}
        </div>
        {cards.map((card) => (
          <div key={`media-card-${card.id}`} className={styles.card()}>
            <MediaCard theme={theme} sectionName={sectionName} data={card} />
          </div>
        ))}
      </div>
      {hasMoreItems && (
        <div className={styles.footer()}>
          <Button
            color="acqua"
            isLoading={loading}
            loadingLabel="Loading"
            appearance="solid"
            onClick={() => getMoreItems()}
          >
            {buttonLabel}
          </Button>
        </div>
      )}
    </div>
  )
}

export default BlogListGrid

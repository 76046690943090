import { tv, VariantProps } from '@/utils/tv'

export const blogList = tv({
  slots: {
    base: ['relative', 'py-28 lg:py-36', 'overflow-hidden'],
    header: ['max-w-7xl', 'mx-auto', 'px-6 lg:px-8', 'text-center mb-8'],
    preTitle: ['mb-2'],
    title: ['max-w-md md:max-w-full', 'mx-auto'],
    description: ['max-w-6xl', 'mx-auto mt-4'],
    categories: ['mb-16', 'hide-scrollbar', 'overflow-x-auto'],
    list: [
      'inline-flex',
      'md:flex justify-center gap-2',
      'py-2',
      'px-6',
      'whitespace-nowrap',
      'snap-x snap-mandatory'
    ]
  },
  variants: {
    theme: {
      dark: {
        base: 'bg-black',
        preTitle: ['text-acqua-500'],
        description: ['text-gray-40']
      },
      light: {
        base: 'bg-white',
        preTitle: ['text-acqua-900'],
        description: ['text-gray-60']
      }
    }
  },
  defaultVariants: {
    theme: 'dark'
  }
})

export const blogGrid = tv({
  slots: {
    base: ['max-w-7xl', 'mx-auto', 'px-6 lg:px-8'],
    content: ['grid grid-cols-12 gap-y-12 sm:gap-10'],
    highlight: ['col-span-12'],
    card: ['col-span-12 sm:col-span-6 lg:col-span-4'],
    footer: ['w-full', 'flex items-center justify-center', 'mt-20']
  }
})

export type BlogListVariantProps = VariantProps<typeof blogList>

export type BlogListReturnVariantProps = ReturnType<typeof blogList>

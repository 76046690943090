'use client'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper/modules'
import { MediaCard } from '@/components/ui/media-card'
import { useVideoOrImageCarouselProps } from './use-video-or-image-carousel'

export type VideoOrImageCarouselSwiperProps = Pick<
  useVideoOrImageCarouselProps,
  'className' | 'sectionName' | 'data'
>

const VideoOrImageCarouselSwiper = ({
  className = '',
  sectionName,
  data
}: VideoOrImageCarouselSwiperProps) => {
  const { theme, cards = [] } = data

  return (
    <Swiper
      className={className}
      modules={[Autoplay]}
      speed={800}
      dir="ltr"
      spaceBetween={40}
      slidesPerView={3}
      centeredSlides={true}
      loop
      loopAdditionalSlides={3}
      watchSlidesProgress
      autoplay={{
        delay: 3000,
        pauseOnMouseEnter: true,
        disableOnInteraction: true
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        520: {
          slidesPerView: 2,
          spaceBetween: 30
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      }}
    >
      {cards.map((card) => (
        <SwiperSlide key={`media-card-${card.id}`}>
          {({ isVisible }) => (
            <MediaCard
              className={!isVisible ? 'md:opacity-20' : ''}
              sectionName={sectionName}
              theme={theme}
              data={card}
            />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default VideoOrImageCarouselSwiper

import { tv, VariantProps } from '@/utils/tv'

export const accordionItem = tv({
  slots: {
    base: ['w-full border-b'],
    title: ['leading-tight text-display-18'],
    button: [
      'text-left',
      'w-full',
      'mb-8',
      'flex items-center justify-between',
      'rounded-sm',
      'outline-none lg:focus:outline-offset-8 focus:outline-acqua-500'
    ],
    icon: [
      'w-4 h-4',
      'ml-4',
      'opacity-50 ease-in-out duration-300',
      'text-gray-50'
    ],
    content: ['overflow-hidden']
  },
  variants: {
    theme: {
      dark: {
        base: ['border-gray-100'],
        title: ['text-white']
      },
      light: {
        base: ['border-gray-20'],
        title: ['text-white']
      }
    },
    isExpanded: {
      true: {
        icon: ['rotate-180']
      }
    }
  }
})

export type AccordionItemVariantProps = VariantProps<typeof accordionItem>

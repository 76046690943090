import { extractYouTubeParams } from '@/utils/youtube'
import {
  YoutubeVideoVariantProps,
  youtubeVideo
} from './youtube-video.variants'

export type useYoutubeVideoProps = YoutubeVideoVariantProps & {
  className?: string
  containerClassName?: string
  url: string
}

export const useYoutubeVideo = (props: useYoutubeVideoProps) => {
  const { className = '', containerClassName = '', url } = props

  const styles = youtubeVideo()

  const youtubeParams = extractYouTubeParams(url)
  const { youtubeId, startTime } = youtubeParams

  return {
    className,
    containerClassName,
    youtubeId,
    startTime,
    styles
  }
}

'use client'

import { useMemo, useState } from 'react'
import { BlockProps, SearchList } from '@/types'
import { searchList } from './search-list.variants'
import useFilterData from '@/hooks/use-filter-data'

export type useSearchListProps = BlockProps<SearchList>

export const useSearchList = (props: useSearchListProps) => {
  const {
    className = '',
    sectionName = '',
    data: { theme = 'dark', columns = 3, cards = [], ...restData }
  } = props

  const [searchParam, setSearchParam] = useState<string>()

  const styles = useMemo(
    () =>
      searchList({
        theme,
        // @ts-ignore
        columns
      }),
    [theme, columns]
  )

  const filteredData = useFilterData(cards, searchParam, [
    'title',
    'description'
  ])

  return {
    className,
    sectionName,
    theme,
    setSearchParam,
    data: {
      cards: filteredData,
      ...restData
    },
    styles
  }
}

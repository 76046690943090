import { useMemo, useRef } from 'react'
import { type SvgName } from '@/svg'
import { moisesPlayer } from './moises-player.variants'
import { useWavesurfer } from './use-wavesurfer'

export type useMoisesPlayerProps = {
  className?: string
  icon?: SvgName
  tracks: {
    title?: string | null
    url: string
  }[]
}

const waveSurferOptions = {
  waveColor: '#474747',
  progressColor: '#ffffff',
  cursorWidth: 0,
  barWidth: 1,
  barGap: 2,
  height: 40,
  dragToSeek: true,
  normalize: true
}

export const useMoisesPlayer = (props: useMoisesPlayerProps) => {
  const { className = '', icon, tracks } = props

  const containerRefs = useRef<(HTMLDivElement | null)[]>([])

  const wavesurfer = useWavesurfer({
    containerRefs,
    tracks: tracks.map(({ url }) => url),
    ...waveSurferOptions
  })

  const isMultiTrackPlayer = useMemo(() => tracks.length > 1, [tracks.length])

  const styles = useMemo(
    () =>
      moisesPlayer({
        isReady: wavesurfer.isAllTracksReady
      }),
    [wavesurfer.isAllTracksReady]
  )

  return {
    className,
    styles,
    icon,
    containerRefs,
    tracks,
    isMultiTrackPlayer,
    wavesurfer
  }
}

import qs from 'qs'
import { getStrapiURL } from '@/utils/strapi'
import { isPreview } from '@/utils/env'
import { StrapiError } from './StrapiError'

interface StrapiFetchProps {
  path: string
  params?: Record<string, string | number>
  options?: RequestInit
}

const strapiFetch = async <T>({
  path,
  params = {},
  options = {}
}: StrapiFetchProps): Promise<T | never> => {
  try {
    const { next } = options

    const mergedOpts: RequestInit = {
      headers: {
        'Content-Type': 'application/json'
      },
      ...(isPreview && {
        cache: 'no-store'
      }),
      ...options,

      ...((next?.tags || next?.revalidate) && {
        next: {
          ...(next?.tags && { tags: next.tags }),
          ...(next?.revalidate && { revalidate: next.revalidate })
        }
      })
    }

    const queryString = qs.stringify(params)

    const resource = queryString ? `?${queryString}` : ``
    const requestUrl = getStrapiURL(`/${path}${resource}`)

    const response = await fetch(requestUrl, mergedOpts)

    if (!response.ok) {
      throw new StrapiError(`Failed [${requestUrl}]`, {
        status: response.status
      })
    }

    const data = await response.json()

    return data
  } catch (error) {
    if (error instanceof StrapiError) {
      throw new StrapiError(error.message, error.params)
    }

    throw error
  }
}

export { strapiFetch }

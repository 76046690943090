import { Icon } from '../icon'
import { Portal } from '../portal'
import { useModal, useModalProps } from './use-modal'

export type ModalProps = useModalProps

const MediaCard = (props: ModalProps) => {
  const {
    modalRef,
    className,
    children,
    active,
    hideCloseButton,
    handleClose,
    styles
  } = useModal(props)

  if (!active) return null

  return (
    <Portal target="modal-portal">
      <div
        data-component-name="modal"
        className={styles.base({ className })}
        tabIndex={-1}
        aria-hidden={active}
        aria-modal="true"
        role="dialog"
      >
        <div className={styles.overlay()}>
          <div className={styles.wrapper()}>
            <div ref={modalRef} className={styles.content()}>
              {!hideCloseButton && (
                <button
                  onClick={handleClose}
                  autoFocus
                  className={styles.close()}
                >
                  <Icon name="close" className={styles.icon()} />
                </button>
              )}
              {children}
            </div>
          </div>
        </div>
      </div>
    </Portal>
  )
}

export default MediaCard

import { tv, VariantProps } from '@/utils/tv'

export const teamCard = tv({
  slots: {
    base: ['flex flex-col h-full', 'rounded-3xl border'],
    figure: ['aspect-w-16 aspect-h-12', 'rounded-t-3xl', 'overflow-hidden'],
    image: ['w-full h-full', 'object-center object-cover'],
    caption: ['p-6 border-t'],
    name: ['mb-1'],
    role: [],
    bio: ['mt-3']
  },
  variants: {
    theme: {
      dark: {
        base: ['bg-gray-800 border-gray-400'],
        caption: ['border-gray-400'],
        role: ['text-acqua-500'],
        bio: ['text-gray-40']
      },
      light: {
        base: ['bg-gray-10 border-gray-20'],
        caption: ['border-gray-20'],
        role: ['text-acqua-900'],
        bio: ['text-gray-60']
      }
    }
  },
  defaultVariants: {
    theme: 'dark'
  }
})

export type TeamCardVariantProps = VariantProps<typeof teamCard>

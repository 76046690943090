import { slugify } from '@/utils/text'
import { Text } from '../text'
import { useScrollCard, useScrollCardProps } from './use-scroll-card'
import ScrollCardVideo from './scroll-card.video'
import { ArtDirection } from '../art-direction'
import { ButtonGroup } from '../button'

export type ScrollCardProps = useScrollCardProps

const ScrollCard = (props: ScrollCardProps) => {
  const {
    cardRef,
    className,
    sectionName,
    styles,
    cardStyle,
    theme,
    hasMedia,
    backgroundType,
    data: {
      title,
      subtitle,
      description,
      buttons,
      background,
      backgroundMobile,
      backgroundSize
    }
  } = useScrollCard(props)

  return (
    <article
      id={slugify(title)}
      ref={cardRef as any}
      data-component-name="scroll-card"
      className={styles.base({ className })}
      style={cardStyle}
    >
      <div className={styles.container()}>
        <div className={styles.caption()}>
          <Text
            tag="h2"
            size="h5"
            theme={theme}
            className={styles.title()}
            weight="medium"
          >
            {title}
          </Text>
          {!!subtitle && (
            <Text
              tag="h3"
              size="h6"
              theme={theme}
              className={styles.subtitle()}
              weight="medium"
            >
              {subtitle}
            </Text>
          )}
          {!!description && (
            <Text
              theme={theme}
              className={styles.description()}
              weight="medium"
            >
              {description}
            </Text>
          )}
          <ButtonGroup
            sectionName={sectionName}
            buttons={buttons}
            theme={theme}
            align="left"
            className={styles.cta()}
            hideStoresOnDesktop={false}
          />
        </div>
        {hasMedia && (
          <div className={styles.media()}>
            <ArtDirection
              fill
              hiddenOn="md"
              sizes="(max-width: 768px) 100vw, 50vw"
              className={styles.image()}
              containerClassName={styles.media_container()}
              desktop={{
                src: background?.url,
                alt: background?.alternativeText
              }}
              mobile={{
                src: backgroundMobile?.url,
                alt: backgroundMobile?.alternativeText
              }}
            />
            <ScrollCardVideo
              className={styles.image()}
              background={background}
              backgroundSize={backgroundSize}
              type={backgroundType}
            />
          </div>
        )}
      </div>
    </article>
  )
}

export default ScrollCard

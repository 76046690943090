'use client'

import { useCallback, useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { CardContentStickyScroll, Theme } from '@/types'
import useActiveItemInView from '@/hooks/use-active-item-in-view'
import { ScrollCard } from '@/components/ui/scroll-card'
import { cardColors } from './card-content-sticky-scroll.variants'
import CardContentStickyScrollNav from './card-content-sticky-scroll.nav'

type CardContentStickyScrollCardsProps = Pick<
  CardContentStickyScroll,
  'theme' | 'cards'
> & {
  sectionName?: string
  cardTheme: Theme
}

const CardContentStickyScrollCards = ({
  sectionName = '',
  theme,
  cardTheme,
  cards
}: CardContentStickyScrollCardsProps) => {
  const [distance, setDistance] = useState<any[]>([])

  const { currentIndex, useItemObserver } = useActiveItemInView()
  const { width } = useWindowSize()

  const calculateTop = useCallback(
    (i = 0) => {
      if (width <= 1024) return {}

      const distance = i * 20
      return { top: 194 + distance }
    },
    [width]
  )

  useEffect(() => {
    const calcTop = cards.map((_, i) => calculateTop(i))
    setDistance(calcTop)
  }, [calculateTop, cards, width])

  const getCardBackground = (index: number) => {
    const color = index % cardColors[cardTheme].length
    return cardColors[cardTheme][color]
  }

  if (cards.length === 1) {
    return (
      <ScrollCard
        sectionName={sectionName}
        index={0}
        theme={cardTheme}
        className={getCardBackground(0)}
        data={cards[0]}
        getItemObserver={useItemObserver}
      />
    )
  }

  return (
    <>
      <CardContentStickyScrollNav
        currentIndex={currentIndex}
        theme={theme}
        cards={cards}
      />
      <div className="grid gap-y-10">
        {cards.map((card, i) => (
          <ScrollCard
            key={`slider-card-${card.id}`}
            sectionName={sectionName}
            index={i}
            theme={cardTheme}
            className={getCardBackground(i)}
            data={card}
            cardStyle={distance[i]}
            getItemObserver={useItemObserver}
          />
        ))}
      </div>
    </>
  )
}

export default CardContentStickyScrollCards

import { useMemo } from 'react'
import { Media } from '@/types'
import { AvatarVariantProps, avatar } from './avatar.variants'

export type useAvatarProps = AvatarVariantProps & {
  className?: string
  image: Media
}

export const useAvatar = (props: useAvatarProps) => {
  const { className = '', theme = 'dark', size = 'md', image } = props

  const styles = useMemo(() => avatar({ theme, size }), [theme, size])

  return {
    className,
    styles,
    image
  }
}

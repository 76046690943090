'use client'

import NextImage from 'next/image'
import YouTube from 'react-youtube'
import { Icon } from '../icon'
import {
  useYoutubeVideoFull,
  useYoutubeVideoFullProps
} from './use-youtube-video-full'

export type YoutubeVideoFullProps = useYoutubeVideoFullProps

const YoutubeVideoFull = (props: YoutubeVideoFullProps) => {
  const {
    videoRef,
    styles,
    className,
    title,
    cover,
    video,
    opts,
    playVideo,
    onPlay,
    onEnd
  } = useYoutubeVideoFull(props)

  if (!video) {
    return null
  }

  const videoId = video[1]

  return (
    <div className={styles.base({ className })}>
      {!!cover && (
        <div className={styles.cover()} onClick={playVideo}>
          <span className={styles.button()}>
            <Icon name="play" className={styles.icon()} />
          </span>
          <NextImage
            fill
            src={cover.url}
            alt={cover?.alternativeText || ''}
            className={styles.image()}
            sizes="(max-width: 768px) 100vw, 50vw"
          />
        </div>
      )}
      <YouTube
        ref={videoRef}
        title={title as string}
        className={styles.player()}
        iframeClassName={styles.iframe()}
        videoId={videoId}
        opts={opts}
        onPlay={onPlay}
        onEnd={onEnd}
      />
    </div>
  )
}

export default YoutubeVideoFull

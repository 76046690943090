'use client'

import { useMemo, useRef } from 'react'
import { VideoVariantProps, video } from './video.variants'

export type useVideoProps = VideoVariantProps & {
  className?: string
  containerClassName?: string
  mime: string
} & React.VideoHTMLAttributes<HTMLVideoElement>

export const useVideo = (props: useVideoProps) => {
  const ref = useRef<HTMLVideoElement | null>(null)
  const {
    className = '',
    containerClassName = '',
    size = 'video',
    src: url,
    mime,
    preload = 'metadata',
    playsInline = true,
    autoPlay = false,
    loop = true,
    muted = true,
    controls = false,
    disablePictureInPicture = true,
    disableRemotePlayback = true,
    ...componentProps
  } = props

  const styles = useMemo(() => video({ size }), [size])

  const handlers = useMemo(() => {
    let videoLoaded: Promise<void> | undefined

    return {
      start() {
        if (!url || !ref.current) return
        videoLoaded = ref.current.play()
      },
      pause() {
        if (!url || !ref.current || !videoLoaded) return
        videoLoaded.then(() => {
          if (ref.current) {
            ref.current.pause()
          }
        })
      }
    }
  }, [url])

  return {
    ref,
    className,
    containerClassName,
    handlers,
    url,
    mime,
    styles,
    componentProps: {
      preload,
      playsInline,
      autoPlay,
      loop,
      muted,
      ...componentProps
    }
  }
}

import { BlogPost } from '@/types'
import { strapiFetch } from '@/services/client'
import { Locale } from '@/config/locales'
import { isPreview } from '@/utils/env'
import { createLocalizableTag } from '@/utils/locale'

const getAllBlogPosts = async (
  locale: Locale,
  limit = -1,
  category?: string
) => {
  const params: Record<string, string | number> = {
    _locale: locale,
    _limit: limit,
    _sort: 'date:desc'
  }

  if (!isPreview) {
    params.status = 'published_date'
  }

  if (!!category) {
    params['category.slug'] = category
  }

  const tag = createLocalizableTag('posts', locale)

  return await strapiFetch<BlogPost[]>({
    path: `blog-posts`,
    params,
    options: {
      next: {
        tags: [tag],
        revalidate: !isPreview ? 72000 : undefined // 20 hours
      }
    }
  })
}

export default getAllBlogPosts

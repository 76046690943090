import { Avatar } from '../avatar'
import { Icon } from '../icon'
import { Text } from '../text'
import { useReviewCard, useReviewCardProps } from './use-review-card'

export type ReviewCardProps = useReviewCardProps

const rates = [1, 2, 3, 4, 5]

const ReviewCard = (props: ReviewCardProps) => {
  const {
    className,
    styles,
    theme,
    data: { avatar, name, role, description, stars },
    isGhostCard
  } = useReviewCard(props)

  return (
    <article
      data-component-name="review-card"
      className={styles.base({ className })}
    >
      {isGhostCard && !!stars && (
        <div className={styles.rating()}>
          {rates.map((rating) => (
            <Icon
              key={`star-${rating}`}
              name="star"
              className={styles.star({
                className: rating <= stars ? 'text-yellow-400' : 'text-gray-50'
              })}
            />
          ))}
        </div>
      )}
      <div className={styles.body()}>
        {!isGhostCard && <Icon name="quote" className={styles.quote()} />}
        <Text
          className={styles.description({
            className: isGhostCard ? '' : 'text-display-18'
          })}
          weight="medium"
        >
          {description}
        </Text>
      </div>
      <footer className={styles.author()}>
        {!!avatar && <Avatar theme={theme} image={avatar} size="xl" />}
        <div className={styles.authorDetails()}>
          <Text tag="cite" className={styles.name()} weight="medium">
            {name}
          </Text>
          {!!role && (
            <Text tag="span" className={styles.role()} weight="medium">
              {role}
            </Text>
          )}
        </div>
      </footer>
    </article>
  )
}

export default ReviewCard

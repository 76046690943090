class StrapiError extends Error {
  params?: Record<string, unknown>

  constructor(message: string, params = {}) {
    super(message)

    this.name = 'StrapiError'
    this.params = params

    Object.setPrototypeOf(this, StrapiError.prototype)
  }
}

export { StrapiError }

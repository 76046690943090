import { useMemo } from 'react'
import { SwiperNavVariantProps, swiperNav } from './swiper-nav.variants'
import { ButtonVariantProps } from '../button/button.variants'

export type useSwiperNavProps = {
  className?: string
  id: string | number
} & SwiperNavVariantProps

export const useSwiperNav = (props: useSwiperNavProps) => {
  const { id, className = '', theme = 'dark' } = props

  const styles = useMemo(() => swiperNav({ theme }), [theme])

  const buttonColor: ButtonVariantProps['color'] =
    theme === 'dark' ? 'gray' : 'white'

  return {
    id,
    className,
    buttonColor,
    styles
  }
}

'use client'

import { useMemo, useRef } from 'react'
import { ModalVariantProps, modal } from './modal.variants'
import { useClickAway, useKeyPressEvent, useLockBodyScroll } from 'react-use'

export type useModalProps = ModalVariantProps & {
  className?: string
  children?: React.ReactNode
  active?: boolean
  handleClose: () => void
  hideCloseButton?: boolean
}

export const useModal = (props: useModalProps) => {
  const {
    className = '',
    children,
    centered = true,
    size = 'xl',
    active = false,
    handleClose = () => {},
    hideCloseButton = false
  } = props

  const modalRef = useRef(null)

  const styles = useMemo(() => modal({ centered, size }), [centered, size])

  useLockBodyScroll(active)
  useClickAway(modalRef, handleClose)
  useKeyPressEvent('Escape', handleClose)

  return {
    modalRef,
    className,
    children,
    handleClose,
    hideCloseButton,
    active,
    styles
  }
}

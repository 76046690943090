import { useMediaCard, useMediaCardProps } from './use-media-card'
import MediaCardFigure from './media-card.figure'
import MediaCardCaption from './media-card.caption'

export type MediaCardProps = useMediaCardProps

const MediaCard = (props: MediaCardProps) => {
  const {
    className,
    theme,
    styles,
    appearance,
    data,
    hasContent,
    showCaption,
    isYoutubeVideo,
    isHorizontalLayout
  } = useMediaCard(props)

  const { title, cover, url } = data

  return (
    <article
      data-component-name="media-card"
      className={styles.base({ className })}
    >
      <div className={styles.figure()}>
        <MediaCardFigure
          styles={styles}
          appearance={appearance}
          title={title}
          cover={cover}
          url={url}
          isYoutubeVideo={isYoutubeVideo}
        />
      </div>
      {showCaption && (
        <MediaCardCaption
          styles={styles}
          theme={theme}
          data={data}
          hasContent={hasContent}
          isYoutubeVideo={isYoutubeVideo}
          isHorizontalLayout={isHorizontalLayout}
          appearance={appearance}
        />
      )}
    </article>
  )
}

export default MediaCard

import NextImage from 'next/image'
import { useArtDirection, useArtDirectionProps } from './use-art-direction'

export type ArtDirectionProps = useArtDirectionProps

const ArtDirection = (props: ArtDirectionProps) => {
  const {
    containerClassName,
    className,
    shouldRender,
    desktop,
    mobile,
    styles,
    ...componentProps
  } = useArtDirection(props)

  if (!shouldRender) return null

  return (
    <>
      {desktop && (
        <div className={styles.desktop({ className: containerClassName })}>
          <NextImage
            {...(desktop as any)}
            {...componentProps}
            className={className}
            quality={85}
          />
        </div>
      )}
      {mobile && (
        <div className={styles.mobile({ className: containerClassName })}>
          <NextImage
            {...(mobile as any)}
            {...componentProps}
            className={className}
            quality={85}
          />
        </div>
      )}
    </>
  )
}

export default ArtDirection

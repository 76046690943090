import { Text } from '../text'
import { useSliderCard, useSliderCardProps } from './use-slider-card'
import SliderCardVideo from './slider-card.video'
import { ArtDirection } from '../art-direction'
import { ButtonGroup } from '../button'

export type SliderCardProps = useSliderCardProps

const SliderCard = (props: SliderCardProps) => {
  const {
    className,
    sectionName,
    styles,
    theme,
    hasMedia,
    backgroundType,
    data: {
      title,
      subtitle,
      description,
      buttons,
      background,
      backgroundMobile,
      backgroundSize
    }
  } = useSliderCard(props)

  return (
    <article
      data-component-name="slider-card"
      className={styles.base({ className })}
    >
      <div className={styles.wrapper()}>
        <div className={styles.content()}>
          <div className={styles.caption()}>
            <Text
              tag="h2"
              size="h5"
              theme={theme}
              className={styles.title()}
              weight="medium"
            >
              {title}
            </Text>
            {!!subtitle && (
              <Text
                tag="h3"
                size="h6"
                theme={theme}
                className={styles.subtitle()}
                weight="medium"
              >
                {subtitle}
              </Text>
            )}
            {!!description && (
              <Text
                theme={theme}
                className={styles.description()}
                weight="medium"
              >
                {description}
              </Text>
            )}
            <ButtonGroup
              sectionName={sectionName}
              buttons={buttons}
              theme={theme}
              align="left"
              className={styles.cta()}
              hideStoresOnDesktop={false}
            />
          </div>
          {hasMedia && (
            <div className={styles.media()}>
              <ArtDirection
                fill
                hiddenOn="md"
                className={styles.image()}
                containerClassName={styles.media_container()}
                sizes="(max-width: 768px) 100vw, 50vw"
                desktop={{
                  src: background?.url,
                  alt: background?.alternativeText
                }}
                mobile={{
                  src: backgroundMobile?.url,
                  alt: backgroundMobile?.alternativeText
                }}
              />
              <SliderCardVideo
                background={background}
                backgroundSize={backgroundSize}
                type={backgroundType}
              />
            </div>
          )}
        </div>
      </div>
    </article>
  )
}

export default SliderCard

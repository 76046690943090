import { type Root } from 'mdast'
import { visit } from 'unist-util-visit'
import { slug } from 'github-slugger'
import { toString } from 'mdast-util-to-string'

export type TocItem = {
  value: string
  url: string
  depth: number
  parent?: string
  level?: number[]
}

const createToc = (tree: Root) => {
  const toc: TocItem[] = []

  visit(tree, 'heading', (node, index, parent) => {
    const depth = node.depth
    if (depth != 2) return

    const textContent = toString(node)
    const parentType = parent?.type

    toc.push({
      value: textContent,
      url: `#${slug(textContent)}`,
      depth: depth,
      parent: parentType
    })
  })

  return toc
}

export default createToc

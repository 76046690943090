'use client'

import YouTube from 'react-youtube'
import { useYoutubeVideo, useYoutubeVideoProps } from './use-youtube-video'

export type YoutubeVideoProps = useYoutubeVideoProps

const YoutubeVideo = (props: YoutubeVideoProps) => {
  const { className, containerClassName, youtubeId, startTime, styles } =
    useYoutubeVideo(props)

  if (!youtubeId) {
    return null
  }

  return (
    <YouTube
      videoId={youtubeId}
      iframeClassName={styles.base({ className })}
      className={styles.video({ className: containerClassName })}
      opts={{
        playerVars: {
          start: startTime
        }
      }}
    />
  )
}

export default YoutubeVideo

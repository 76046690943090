/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import {
  ReactNode,
  createContext,
  useCallback,
  useState,
  MouseEvent
} from 'react'

type AccordionContextProps = {
  isExpanded: (slug: string) => boolean
  toggle: (e: MouseEvent, slug: string) => void
}

type AccordionProviderProps = {
  initialExpanded: boolean | string
  total: number
  children: ReactNode
}

const initialAccordionState = {
  isExpanded: (slug: string) => false,
  toggle: () => {}
}

const AccordionContext = createContext<AccordionContextProps>(
  initialAccordionState
)

const AccordionProvider = ({
  initialExpanded,
  total,
  children
}: AccordionProviderProps) => {
  const [expanded, setExpanded] = useState(initialExpanded)

  const isExpanded = useCallback(
    (slug: string) => slug === expanded,
    [expanded]
  )

  const toggle = useCallback(
    (e: MouseEvent, slug: string) => {
      if (total === 1) return

      e.preventDefault()
      setExpanded(isExpanded(slug) ? false : slug)
    },
    [isExpanded, total]
  )

  return (
    <AccordionContext.Provider value={{ isExpanded, toggle }}>
      {children}
    </AccordionContext.Provider>
  )
}

export { AccordionContext, AccordionProvider }

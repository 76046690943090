import { strapiUrl } from '@/utils/env'

const getStrapiURL = (path = '') => `${strapiUrl}${path}`

const getStrapiMedia = (url: string | null) => {
  if (url === null) {
    return null
  }

  if (url.startsWith('http') || url.startsWith('//')) {
    return url
  }

  return getStrapiURL(url)
}

export { getStrapiURL, getStrapiMedia }

'use client'

import { useState, useEffect, useRef } from 'react'
import { motion, useAnimation, useInView } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import { Columns2ImageAndContent } from '@/types'
import { SwiperNav } from '@/components/ui/swiper-nav'
import { SliderCard } from '@/components/ui/slider-card'
import Columns2ImageAndContentNav from './columns2-image-and-content.nav'
import { Columns2ImageAndContentVariantReturnProps } from './columns2-image-and-content.variants'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

export type Columns2ImageAndContentSwiperProps = {
  styles: Columns2ImageAndContentVariantReturnProps
  sectionName: string
  data: Columns2ImageAndContent
}

const Columns2ImageAndContentSwiper = ({
  styles,
  sectionName,
  data
}: Columns2ImageAndContentSwiperProps) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const { id, theme, cards = [], appearance } = data
  const isProAppearance = appearance === 'pro'

  const swiperRef = useRef(null)
  const controls = useAnimation()
  const inView = useInView(swiperRef, {
    amount: 0.5,
    once: true
  })

  useEffect(() => {
    if (inView) {
      controls.start({
        x: [0, -160, 0],
        transition: {
          ease: 'easeInOut',
          duration: 0.6
        }
      })
    }
  }, [controls, inView])

  function onSlideChange(slide) {
    setCurrentIndex(slide.activeIndex)
  }

  return (
    <Swiper
      ref={swiperRef}
      modules={[Navigation, Pagination]}
      spaceBetween={0}
      initialSlide={0}
      slidesPerView={1}
      simulateTouch={false}
      speed={2000}
      navigation={{
        prevEl: `.prev-carousel-${id}`,
        nextEl: `.next-carousel-${id}`
      }}
      breakpoints={{
        0: {
          speed: 600
        },
        768: {
          speed: 1000
        },
        1200: {
          speed: 2000
        }
      }}
      pagination={{
        el: '.swiper-dots',
        clickable: true,
        renderBullet: (index, className) =>
          `<span class="${styles.bullet({ className })}">${index + 1}</span>`
      }}
      onSlideChange={onSlideChange}
    >
      {isProAppearance && (
        <span slot="container-start" className="mb-8">
          <Columns2ImageAndContentNav
            currentIndex={currentIndex}
            theme={theme}
            cards={cards}
          />
        </span>
      )}
      {cards.map((card) => (
        <SwiperSlide
          key={`icon-card-${card.id}`}
          // @ts-ignore
          tag={motion.div}
          animate={controls}
          className={styles.card()}
        >
          <SliderCard
            sectionName={sectionName}
            theme={theme}
            data={card}
            appearance={appearance}
            fixed
          />
        </SwiperSlide>
      ))}
      <div className={styles.bullets({ className: 'swiper-dots' })} />
      {!isProAppearance && (
        <SwiperNav id={id} className={styles.nav()} theme={theme} />
      )}
    </Swiper>
  )
}

export default Columns2ImageAndContentSwiper

'use client'

import { ArtDirection } from '../art-direction'
import { ButtonGroup } from '../button'
import { Text } from '../text'
import { useStepCard, useStepCardProps } from './use-step-card'
import StepCardVideo from './step-card.video'

export type StepCardProps = useStepCardProps

const StepCard = (props: StepCardProps) => {
  const {
    stepRef,
    sectionName,
    className,
    styles,
    theme,
    highlightType,
    hasMedia,
    data: {
      title,
      description,
      buttons,
      background,
      backgroundMobile,
      imageHighlight
    },
    componentProps
  } = useStepCard(props)

  return (
    <article
      ref={stepRef}
      data-component-name="step-card"
      className={styles.base({ className })}
      {...componentProps}
    >
      <div className={styles.wrapper()}>
        <div className={styles.container()}>
          <div className={styles.header()}>
            {!!title && (
              <Text
                theme={theme}
                tag="h3"
                size="h4"
                weight="medium"
                className={styles.title()}
              >
                {title}
              </Text>
            )}
            {!!description && (
              <Text
                theme={theme}
                size="subtitle"
                weight="medium"
                className={styles.description()}
              >
                {description}
              </Text>
            )}
            <ButtonGroup
              theme={theme}
              sectionName={sectionName}
              buttons={buttons}
              className={styles.buttonGroup()}
            />
          </div>
          {hasMedia && (
            <div className={styles.media_container()}>
              <div className={styles.media()}>
                <ArtDirection
                  fill
                  containerClassName={styles.backgroundContainer()}
                  className={styles.background()}
                  hiddenOn="md"
                  desktop={{
                    src: background?.url,
                    alt: background?.alternativeText
                  }}
                  mobile={{
                    src: backgroundMobile?.url,
                    alt: backgroundMobile?.alternativeText
                  }}
                />
                {highlightType === 'image' && (
                  <ArtDirection
                    fill
                    containerClassName={styles.imageContainer()}
                    className={styles.image()}
                    desktop={{
                      src: imageHighlight?.url,
                      alt: imageHighlight?.alternativeText
                    }}
                  />
                )}
                <StepCardVideo
                  containerClassName={styles.videoContainer()}
                  className={styles.video()}
                  type={highlightType}
                  imageHighlight={imageHighlight}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </article>
  )
}

export default StepCard

import { useMemo } from 'react'
import { Writer } from '@/types'
import { Locale } from '@/config/locales'
import { AvatarProps } from '../avatar'
import { TextProps } from '../text'
import { AuthorVariantProps, author } from './author.variants'

export type useAuthorProps = AuthorVariantProps & {
  className?: string
  avatar?: AvatarProps['size']
  writer: Writer
  locale?: Locale
  date?: string
  readTime?: string
  showBio?: boolean
}

export const useAuthor = (props: useAuthorProps) => {
  const {
    className = '',
    theme = 'dark',
    avatar = 'lg',
    writer,
    date,
    showBio = false,
    ...componentProps
  } = props

  const styles = useMemo(() => author({ theme }), [theme])
  const sizeName: TextProps['size'] = !!date ? 'tinyBody' : 'h6'

  return {
    className,
    theme,
    styles,
    sizeName,
    sizeAvatar: avatar,
    writer,
    date,
    showBio,
    ...componentProps
  }
}

'use client'

import { SliderCard } from '@/components/ui/slider-card'
import Columns2ImageAndContentSwiper from './columns2-image-and-content.swiper'
import {
  useColumns2ImageAndContent,
  useColumns2ImageAndContentProps
} from './use-columns2-image-and-content'

export type Columns2ImageAndContentProps = useColumns2ImageAndContentProps

const Columns2ImageAndContent = (props: Columns2ImageAndContentProps) => {
  const { className, sectionName, hasOneCard, data, styles } =
    useColumns2ImageAndContent(props)

  const { theme, cards } = data

  return (
    <section
      data-component-name={sectionName}
      className={styles.base({ className })}
    >
      <div className={styles.wrapper()}>
        {hasOneCard ? (
          <SliderCard sectionName={sectionName} theme={theme} data={cards[0]} />
        ) : (
          <Columns2ImageAndContentSwiper
            styles={styles}
            sectionName={sectionName}
            data={data}
          />
        )}
      </div>
    </section>
  )
}

export default Columns2ImageAndContent

import NextLink from 'next/link'
import { MediaCard, Theme } from '@/types'
import { Author } from '../author'
import { Text } from '../text'
import { MediaCardVariantReturnProps } from './media-card.variants'

type MediaCardCaptionProps = {
  styles: MediaCardVariantReturnProps
  theme: Theme
  data: MediaCard
  isYoutubeVideo?: boolean
  isHorizontalLayout?: boolean
  hasContent?: boolean
  appearance?: 'default' | 'pro'
}

const MediaCardCaption = ({
  styles,
  theme,
  appearance = 'default',
  data,
  isYoutubeVideo = false,
  isHorizontalLayout = false,
  hasContent = false
}: MediaCardCaptionProps) => {
  const { preTitle, title, description, url, writer, date, locale } = data

  const renderTitleAndDescription = () => {
    const sizeTitle =
      appearance === 'pro' ? 'h4' : isHorizontalLayout ? 'h5' : 'subtitle'

    if (!url || isYoutubeVideo) {
      return (
        <>
          {!!title && (
            <Text
              theme={theme}
              tag="h3"
              size={sizeTitle}
              weight="medium"
              className={styles.title()}
            >
              {title}
            </Text>
          )}
          {!!description && (
            <Text
              theme={theme}
              weight="medium"
              className={styles.description()}
            >
              {description}
            </Text>
          )}
        </>
      )
    }

    return (
      <>
        {!!title && (
          <Text
            theme={theme}
            tag="h3"
            size={sizeTitle}
            weight="medium"
            className={styles.title()}
          >
            <NextLink href={url} title={title} className={styles.titleLink()}>
              {title}
            </NextLink>
          </Text>
        )}
        {!!description && (
          <Text theme={theme} weight="medium" className={styles.description()}>
            <NextLink
              href={url}
              title={description}
              tabIndex={-1}
              className={styles.descriptionLink()}
            >
              {description}
            </NextLink>
          </Text>
        )}
      </>
    )
  }

  return (
    <div className={styles.caption()}>
      {hasContent && (
        <header className={styles.header()}>
          {!!preTitle && (
            <Text
              className={styles.preTitle()}
              theme={theme}
              weight="medium"
              size="tinyBody"
            >
              {preTitle}
            </Text>
          )}
          {renderTitleAndDescription()}
        </header>
      )}
      {!!writer && (
        <footer className={styles.footer()}>
          <Author writer={writer} date={date} locale={locale} />
        </footer>
      )}
    </div>
  )
}

export default MediaCardCaption

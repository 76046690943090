'use client'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { CardContentCarousel } from '@/types'
import { SwiperNav } from '@/components/ui/swiper-nav'
import { IconCard } from '@/components/ui/icon-card'

export type CardContentCarouselSwiperProps = {
  className?: string
  navClassName?: string
  sectionName: string
  data: CardContentCarousel
}

const CardContentCarouselSwiper = ({
  className = '',
  navClassName = '',
  sectionName,
  data
}: CardContentCarouselSwiperProps) => {
  const { id, theme, cardAppearance, cards = [] } = data

  return (
    <Swiper
      className={className}
      modules={[Navigation]}
      spaceBetween={40}
      slidesPerView={3}
      simulateTouch={false}
      navigation={{
        prevEl: `.prev-carousel-${id}`,
        nextEl: `.next-carousel-${id}`
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 10
        },
        520: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        993: {
          slidesPerView: 3,
          spaceBetween: 40
        }
      }}
    >
      {cards.map((card) => (
        <SwiperSlide key={`icon-card-${card.id}`} className="!h-auto">
          <IconCard
            sectionName={sectionName}
            appearance={cardAppearance}
            theme={theme}
            data={card}
          />
        </SwiperSlide>
      ))}
      <SwiperNav id={id} className={navClassName} theme={theme} />
    </Swiper>
  )
}

export default CardContentCarouselSwiper

import { useMemo } from 'react'

const useFilterData = (
  data: any[] = [],
  searchParam?: string,
  fields: string[] = []
) => {
  const filteredData = useMemo(() => {
    if (!Array.isArray(data) || !Array.isArray(fields)) {
      return data
    }

    if (searchParam) {
      const lowerCaseSearchParam = searchParam.toLowerCase()

      return data.filter((item) =>
        fields.some(
          (field) =>
            typeof item[field] === 'string' &&
            item[field].toLowerCase().includes(lowerCaseSearchParam)
        )
      )
    }

    return data
  }, [data, searchParam, fields])

  return filteredData
}

export default useFilterData

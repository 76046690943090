'use client'

import { useMemo, useRef } from 'react'
import { useInView } from 'framer-motion'
import { BlockProps, StepCard } from '@/types'
import { StepCardVariantProps, stepCard } from './step-card.variants'
import { getMediaType } from '@/utils/media'

export type useStepCardProps = BlockProps<
  StepCard,
  StepCardVariantProps & { role?: string }
>

export const useStepCard = (props: useStepCardProps) => {
  const {
    className = '',
    theme = 'dark',
    sectionName,
    data: { background, backgroundMobile, imageHighlight, ...restData },
    ...componentProps
  } = props

  const stepRef = useRef(null)
  const isInView = useInView(stepRef, { margin: '-50%' })

  const styles = useMemo(
    () => stepCard({ theme, active: isInView }),
    [theme, isInView]
  )

  const hasMedia = !!background || !!backgroundMobile || !!imageHighlight
  const highlightType = getMediaType(imageHighlight?.url)

  return {
    stepRef,
    theme,
    className,
    sectionName,
    hasMedia,
    highlightType,
    data: {
      background,
      backgroundMobile,
      imageHighlight,
      ...restData
    },
    styles,
    componentProps
  }
}

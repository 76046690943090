import { tv, VariantProps } from '@/utils/tv'

export const moisesPlayer = tv({
  slots: {
    base: [
      'w-full',
      'relative',
      'p-6',
      'bg-gray-800 text-white',
      'flex items-center gap-5',
      'rounded-2xl'
    ],
    icon: ['w-8 h-8'],
    playPlause: [
      'w-10 h-10',
      'shrink-0',
      'flex items-center justify-center',
      'rounded-full',
      'transition-colors duration-500',
      'bg-gray-400 hover:bg-gray-200',
      'focus:!outline-acqua-500',
      'focus-visible:!z-10',
      'focus-visible:!outline-2',
      'focus-visible:!outline-focus',
      'disabled:!opacity-40'
    ],
    playPauseIcon: ['w-6 h-6', 'text-white'],
    waves: ['flex w-full flex-col gap-4', 'relative'],
    spinner: ['absolute inset-y-0', 'm-auto'],
    wavesGroup: ['w-full'],
    wave: ['w-full', 'animate-in fade-in duration-1000', 'cursor-pointer'],
    volumes: ['flex gap-5', 'w-full max-w-[280px]'],
    volume: ['flex flex-col gap-3', 'w-full', 'mt-auto'],
    label: ['!my-0', 'text-gray-60', 'select-none'],
    range: []
  },
  variants: {
    isReady: {
      false: {
        wavesGroup: ['opacity-0'],
        volume: ['opacity-0']
      },
      true: {
        wavesGroup: ['animate-in fade-in duration-1000', 'opacity-100'],
        volume: ['animate-in fade-in duration-1000', 'opacity-100']
      }
    }
  }
})

export type MoisesPlayerVariantProps = VariantProps<typeof moisesPlayer>

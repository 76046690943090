/* eslint-disable react-hooks/exhaustive-deps */
import {
  useState,
  useEffect,
  useMemo,
  type RefObject,
  useCallback
} from 'react'
import WaveSurfer, { type WaveSurferOptions } from 'wavesurfer.js'

interface PlayerPlayEvent extends CustomEvent {
  detail: {
    wavesurfer: WaveSurfer[]
  }
}

declare global {
  interface WindowEventMap {
    'moises-player::play': PlayerPlayEvent
  }
}

export type usePlayerProps = Omit<WaveSurferOptions, 'container' | 'url'> & {
  containerRefs: RefObject<(HTMLElement | null)[]>
  tracks: string[]
}

export const useWavesurfer = (
  props: usePlayerProps
): ReturnType<typeof useWavesurferState> & {
  wavesurfer: ReturnType<typeof useWavesurferInstance>
} => {
  const { containerRefs, tracks, ...options } = props

  const wavesurfer = useWavesurferInstance(containerRefs, tracks, options)
  const state = useWavesurferState(wavesurfer)

  return useMemo(
    () => ({
      ...state,
      wavesurfer
    }),
    [state, wavesurfer]
  )
}

const useWavesurferInstance = (
  containerRefs: RefObject<(HTMLElement | null)[]>,
  tracks: string[],
  options: Partial<WaveSurferOptions>
): WaveSurfer[] => {
  const [wavesurfer, setWavesurfer] = useState<WaveSurfer[]>([])
  const flatOptions = useMemo(
    () => Object.entries({ ...options }).flat(),
    [options]
  )

  useEffect(() => {
    if (!containerRefs.current || !containerRefs.current[0]) return

    const wavesurferInstances: WaveSurfer[] = []

    containerRefs.current.forEach((container, index) => {
      if (container && !wavesurfer[index]) {
        const ws = WaveSurfer.create({
          ...options,
          url: tracks[index],
          container
        })

        wavesurferInstances[index] = ws
      }
    })

    setWavesurfer(wavesurferInstances)

    return () => {
      wavesurferInstances.forEach((ws) => ws?.destroy())
    }
  }, [containerRefs, ...flatOptions])

  return wavesurfer
}

const useWavesurferState = (wavesurfer: WaveSurfer[]) => {
  const [state, setState] = useState({
    isReady: wavesurfer.map(() => false),
    isPlaying: wavesurfer.map(() => false),
    currentIndex: 0,
    currentWave: 0,
    currentProgress: 0
  })

  const [volumes, setVolumes] = useState<number[]>(
    wavesurfer.map((_, index) => (index === 0 ? 0 : 0.5))
  )

  const isAllTracksReady =
    state.isReady.every(Boolean) && state.isReady.length > 0

  const isAllTracksPlaying =
    state.isPlaying.every(Boolean) && state.isPlaying.length > 0

  useEffect(() => {
    if (!wavesurfer || wavesurfer.length === 0) return

    const events: (() => void)[] = []

    wavesurfer.forEach((ws, index) => {
      const handleLoad = () => {
        setState((prev) => {
          const newState = { ...prev }
          newState.isReady[index] = false
          newState.isPlaying[index] = false

          if (index === 0 && wavesurfer.length > 1) {
            ws.setMuted(true)
            setVolumes((prev) => {
              const newState = [...prev]
              newState[index] = 0
              return newState
            })
          } else {
            setVolumes((prev) => {
              const newState = [...prev]
              newState[index] = 0.5
              return newState
            })
          }

          return newState
        })
      }

      const handleReady = () => {
        setState((prev) => {
          const newState = { ...prev }
          newState.isReady[index] = true
          newState.isPlaying[index] = false
          return newState
        })
      }

      const handlePlay = () => {
        setState((prev) => {
          const newState = { ...prev }
          newState.isPlaying[index] = true
          return newState
        })
      }

      const handlePause = () => {
        setState((prev) => {
          const newState = { ...prev }
          newState.isPlaying[index] = false
          return newState
        })
      }

      const handleDestroy = () => {
        setState((prev) => {
          const newState = { ...prev }
          newState.isReady[index] = false
          newState.isPlaying[index] = false
          return newState
        })
      }

      const handleSeek = (progress: number) => {
        setState((prev) => {
          const newState = { ...prev }
          newState.currentProgress = progress
          newState.currentIndex = index
          return newState
        })
      }

      ws.on('load', handleLoad)
      ws.on('ready', handleReady)
      ws.on('play', handlePlay)
      ws.on('pause', handlePause)
      ws.on('destroy', handleDestroy)
      ws.on('seeking', handleSeek)

      events.push(() => {
        ws.un('load', handleLoad)
        ws.un('ready', handleReady)
        ws.un('play', handlePlay)
        ws.un('pause', handlePause)
        ws.un('destroy', handleDestroy)
        ws.on('seeking', handleSeek)
      })
    })

    return () => {
      events.forEach((fn) => fn())
    }
  }, [wavesurfer])

  useEffect(() => {
    wavesurfer.forEach((ws, index) => {
      if (index !== state.currentIndex) {
        ws.setTime(state.currentProgress)
      }
    })
  }, [state.currentProgress, wavesurfer])

  useEffect(() => {
    const showOriginalWave = volumes.slice(1).every((v) => v > 0)
    let currentWave = 0

    if (!showOriginalWave) {
      currentWave = Math.max(
        0,
        volumes.findIndex((v) => v > 0)
      )
    }

    setState((prev) => {
      const newState = { ...prev }
      newState.currentWave = currentWave
      return newState
    })
  }, [volumes])

  useEffect(() => {
    const handlePlayerPlay = (event: CustomEvent) => {
      if (event.detail.wavesurfer !== wavesurfer) {
        wavesurfer.forEach((ws) => ws.pause())
      }
    }

    window?.addEventListener('moises-player::play', handlePlayerPlay)

    return () => {
      window?.removeEventListener('moises-player::play', handlePlayerPlay)
    }
  }, [wavesurfer])

  const playPauseAll = useCallback(() => {
    if (!isAllTracksReady) return

    window.dispatchEvent(
      new CustomEvent('moises-player::play', {
        detail: { wavesurfer }
      })
    )

    wavesurfer.forEach((ws) => ws?.playPause())
  }, [wavesurfer, isAllTracksReady])

  const setVolume = useCallback(
    (index: number, volume: number) => {
      if (wavesurfer.length > 1 && index === 0) return

      if (wavesurfer[index]) {
        wavesurfer[index]?.setVolume(volume)
      }

      setVolumes((prev) => {
        const newState = [...prev]
        newState[index] = volume
        return newState
      })
    },
    [wavesurfer]
  )

  return useMemo(
    () => ({
      volumes,
      currentWave: state.currentWave,
      isAllTracksReady,
      isAllTracksPlaying,
      playPauseAll,
      setVolume
    }),
    [volumes, state.currentWave, isAllTracksReady, isAllTracksPlaying]
  )
}

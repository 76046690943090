'use client'

import {
  useAlternateUpdater,
  useAlternateUpdaterProps
} from './use-alternate-updater'

export type AlternateUpdaterProps = useAlternateUpdaterProps

const AlternateUpdater = (props: AlternateUpdaterProps) => {
  useAlternateUpdater(props)

  return <></>
}

export default AlternateUpdater

'use client'

import { Text } from '@/components/ui/text'
import { useSearchList, useSearchListProps } from './use-search-list'
import { ArtDirection } from '@/components/ui/art-direction'
import { Search } from '@/components/ui/search'
import { IconCard } from '@/components/ui/icon-card'

export type SearchListProps = useSearchListProps

const SearchList = (props: SearchListProps) => {
  const { className, theme, setSearchParam, sectionName, data, styles } =
    useSearchList(props)

  const {
    preTitle,
    title,
    description,
    searchLabel,
    headerBackground,
    headerBackgroundMobile,
    cards,
    cardAppearance
  } = data

  return (
    <section
      data-component-name={sectionName}
      className={styles.base({ className })}
    >
      <header className={styles.header()}>
        <ArtDirection
          containerClassName={styles.figure()}
          className={styles.image()}
          hiddenOn="lg"
          priority
          desktop={{
            src: headerBackground?.url,
            alt: headerBackground?.alternativeText,
            width: headerBackground?.width,
            height: headerBackground?.height
          }}
          mobile={{
            src: headerBackgroundMobile?.url,
            alt: headerBackgroundMobile?.alternativeText,
            width: headerBackgroundMobile?.width,
            height: headerBackgroundMobile?.height
          }}
        />
        <div className={styles.headerWrapper()}>
          {!!preTitle && (
            <Text
              className={styles.preTitle()}
              theme={theme}
              weight="semibold"
              size="tag"
              uppercase
            >
              {preTitle}
            </Text>
          )}
          {!!title && (
            <Text
              theme={theme}
              tag="h1"
              size="h3"
              weight="medium"
              className={styles.title()}
            >
              {title}
            </Text>
          )}
          {!!description && (
            <Text
              theme={theme}
              size="subtitle"
              weight="medium"
              className={styles.description()}
            >
              {description}
            </Text>
          )}
          <Search
            className={styles.search()}
            theme={theme}
            label={searchLabel}
            onChange={(e) => setSearchParam(e.target.value)}
          />
        </div>
      </header>
      <div className={styles.container()}>
        <div className={styles.content()}>
          {cards.map((card) => (
            <IconCard
              key={`icon-card-${card.id}`}
              sectionName={sectionName}
              theme={theme}
              appearance={cardAppearance}
              data={card}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

export default SearchList

import { tv, VariantProps } from '@/utils/tv'

export const stepCard = tv({
  slots: {
    base: [
      'group',
      'relative overflow-hidden',
      'block',
      'w-full h-full',
      'border-t border-l-4 md:border-l-8 last-of-type:border-b',
      'transition-colors duration-500'
    ],
    wrapper: ['max-w-7xl', 'mx-auto px-5 lg:px-8', 'h-full'],
    container: [
      'w-full h-full',
      'flex items-center flex-col md:flex-row md:gap-10'
    ],
    header: [
      'pt-10 md:py-20 sm:pr-10 md:w-1/2',
      'before:w-[60px] before:h-[60px]',
      'before:flex before:justify-center before:items-center',
      'before:rounded-full',
      'before:mb-5 before:text-display-20',
      'before:transition-colors before:duration-500',
      'before:[counter-increment:step]',
      'before:content-[counter(step)]',
      'before:font-semibold'
    ],
    title: ['max-w-lg'],
    description: ['mt-4 max-w-3xl'],
    buttonGroup: ['mt-8'],
    media_container: ['w-full md:w-1/2 relative mt-auto'],
    media: ['aspect-w-1 aspect-h-1'],
    backgroundContainer: ['absolute inset-0'],
    background: ['object-cover'],
    imageContainer: ['absolute inset-0 z-10'],
    image: ['object-contain'],
    videoContainer: ['flex items-end'],
    video: ['object-contain']
  },
  variants: {
    theme: {
      dark: {
        base: 'text-white bg-black border-gray-100',
        header: [
          'before:bg-gray-100',
          'group-hover:before:md:bg-acqua-500 group-hover:before:md:text-black'
        ],
        description: 'text-gray-40'
      },
      light: {
        base: 'text-black bg-white border-gray-10',
        header: ['before:bg-gray-10'],
        description: 'text-gray-60'
      }
    },
    active: {
      false: {},
      true: {}
    },
    type: {
      invalid: '',
      image: '',
      video: ''
    }
  },
  defaultVariants: {
    theme: 'dark'
  },
  compoundVariants: [
    {
      theme: 'dark',
      active: true,
      className: {
        base: ['border-l-acqua-500 bg-gray-800'],
        header: ['before:bg-acqua-500 before:text-black']
      }
    },
    {
      theme: 'light',
      active: true,
      className: {
        base: ['border-l-acqua-700 bg-gray-5'],
        header: ['before:bg-acqua-700']
      }
    }
  ]
})

export type StepCardVariantProps = VariantProps<typeof stepCard>

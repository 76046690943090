/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import { useEffect, useState } from 'react'
import { Locale } from '@/config/locales'
import { Text } from '../text'
import { formatDateToString } from '@/utils/date'

type AuthorDateProps = {
  locale?: Locale
  date: string
}

const dateOpts = {
  weekday: 'short',
  month: 'short',
  day: '2-digit',
  year: 'numeric'
}

const AuthorDate = ({ locale, date: dateProp }: AuthorDateProps) => {
  const [date, setDate] = useState<string>()

  useEffect(() => {
    !date && setDate(formatDateToString(dateProp, locale, dateOpts))
  }, [])

  if (!date) return null

  return (
    <Text tag="span" size="tinyBody" weight="medium">
      {date}
    </Text>
  )
}

export default AuthorDate

import { tv, VariantProps } from '@/utils/tv'

export const avatar = tv({
  slots: {
    base: ['rounded-full shrink-0 border-2 border-opacity-40'],
    image: ['w-full h-full rounded-full']
  },
  variants: {
    theme: {
      dark: {
        base: ['border-white']
      },
      light: {
        base: ['border-black']
      }
    },
    size: {
      sm: {
        base: 'w-4 h-4'
      },
      md: {
        base: 'w-8 h-8'
      },
      lg: {
        base: 'w-12 h-12'
      },
      xl: {
        base: 'w-16 h-16'
      }
    }
  },
  defaultVariants: {
    size: 'md'
  }
})

export type AvatarVariantProps = VariantProps<typeof avatar>

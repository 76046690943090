'use client'

import { useSearch, useSearchProps } from './use-search'

export type SearchProps = useSearchProps

const Search = (props: SearchProps) => {
  const { className, fieldId, styles, label, placeHolder, componentProps } =
    useSearch(props)

  return (
    <div className={styles.base({ className })}>
      <label htmlFor={fieldId} className={styles.label()}>
        {label}
      </label>
      <div className={styles.group()}>
        <div className={styles.iconWrapper()}></div>
        <input
          {...componentProps}
          id={fieldId}
          type="search"
          className={styles.input()}
          placeholder={placeHolder}
          autoComplete="off"
        />
      </div>
    </div>
  )
}

export default Search

import NextImage from 'next/image'
import { Text } from '../text'
import { useTeamCard, useTeamCardProps } from './use-team-card'

export type TeamCardProps = useTeamCardProps

const TeamCard = (props: TeamCardProps) => {
  const { className, styles, theme, data } = useTeamCard(props)
  const { name, role, avatar, bio } = data

  return (
    <article
      data-component-name="team-card"
      className={styles.base({ className })}
    >
      <figure className={styles.figure()}>
        <NextImage
          fill
          sizes="100vw"
          src={avatar.url}
          alt={avatar.alternativeText}
          className={styles.image()}
        />
      </figure>
      <div className={styles.caption()}>
        {!!name && (
          <Text
            theme={theme}
            tag="h3"
            size="subtitle"
            weight="medium"
            className={styles.name()}
          >
            {name}
          </Text>
        )}
        {!!role && (
          <Text theme={theme} weight="medium" className={styles.role()}>
            {role}
          </Text>
        )}
        {!!bio && (
          <Text theme={theme} weight="medium" className={styles.bio()}>
            {bio}
          </Text>
        )}
      </div>
    </article>
  )
}

export default TeamCard

import { useMemo } from 'react'
import { usePathname } from 'next/navigation'
import { BlockProps, Hero } from '@/types'
import { hero } from './hero.variants'
import { getMediaType } from '@/utils/media'
import { ButtonGroupProps } from '@/components/ui/button'

export type useHeroProps = BlockProps<Hero>

const artistPage = /\/made-for\/[^/]+\/[^/]+/

export const useHero = (props: useHeroProps) => {
  const {
    className = '',
    sectionName = '',
    data: {
      theme = 'dark',
      align = 'left',
      full = true,
      background,
      imageHighlight,
      ...restData
    }
  } = props

  const pathname = usePathname()

  const styles = useMemo(
    () => hero({ theme, align, isFull: full }),
    [theme, align, full]
  )

  const isVideoBackground =
    !!background && getMediaType(background?.url) === 'video'

  const buttonAlign: ButtonGroupProps['align'] =
    align === 'center' ? 'center' : 'center_left'

  const isArtistPage = artistPage.test(pathname)
  const highlightType = getMediaType(imageHighlight?.url)

  return {
    className,
    sectionName,
    buttonAlign,
    isArtistPage,
    isVideoBackground,
    highlightType,
    data: {
      theme,
      align,
      background,
      imageHighlight,
      ...restData
    },
    styles
  }
}

'use client'

import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { StepCard } from '@/types'
import { Video } from '../video'
import { StepCardVariantProps } from './step-card.variants'

export type StepCardVideoProps = {
  className?: string
  containerClassName?: string
} & Pick<StepCard, 'imageHighlight'> &
  Pick<StepCardVariantProps, 'type'>

const StepCardVideo = ({
  className = '',
  containerClassName = '',
  type,
  imageHighlight
}: StepCardVideoProps) => {
  const [showVideo, setShowVideo] = useState(false)
  const { width } = useWindowSize()

  useEffect(() => {
    if (width >= 768 && type === 'video') {
      !showVideo && setShowVideo(true)
    } else {
      showVideo && setShowVideo(false)
    }
  }, [width, showVideo, type])

  if ((type !== 'video' && !showVideo) || !imageHighlight) return null

  return (
    <Video
      size="full"
      className={className}
      containerClassName={containerClassName}
      src={imageHighlight.url}
      mime={imageHighlight.mime}
    />
  )
}

export default StepCardVideo

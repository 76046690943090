import { useMemo } from 'react'
import { BlockProps, TeamCard } from '@/types'
import { TeamCardVariantProps, teamCard } from './team-card.variants'

export type useTeamCardProps = BlockProps<TeamCard, TeamCardVariantProps>

export const useTeamCard = (props: useTeamCardProps) => {
  const {
    className = '',
    theme = 'dark',
    sectionName,
    data,
    ...componentProps
  } = props

  const styles = useMemo(() => teamCard({ theme }), [theme])

  return {
    theme,
    className,
    sectionName,
    data,
    styles,
    componentProps
  }
}

import { CSSProperties, RefObject, useMemo } from 'react'
import { BlockProps, SliderCard } from '@/types'
import { ScrollCardVariantProps, scrollCard } from './scroll-card.variants'
import { getMediaType } from '@/utils/media'

export type useScrollCardProps = BlockProps<
  SliderCard,
  ScrollCardVariantProps & {
    index: number
    cardStyle?: CSSProperties
    getItemObserver: (index: number) => RefObject<Element>
  }
>

export const useScrollCard = (props: useScrollCardProps) => {
  const {
    className = '',
    theme = 'dark',
    sectionName,
    index,
    cardStyle,
    getItemObserver,
    data: { background, backgroundMobile, backgroundSize, ...restData }
  } = props

  const backgroundType = getMediaType(background?.url)

  const styles = useMemo(
    () =>
      scrollCard({ theme, background: backgroundSize, type: backgroundType }),
    [theme, backgroundSize, backgroundType]
  )

  const cardRef = getItemObserver(index)
  const hasMedia = !!background || !!backgroundMobile

  return {
    cardRef,
    theme,
    className,
    sectionName,
    hasMedia,
    cardStyle,
    backgroundType,
    data: {
      ...restData,
      background,
      backgroundMobile,
      backgroundSize
    },
    styles
  }
}

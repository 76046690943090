'use client'

import { usePathname } from 'next/navigation'
import { Button } from '@/components/ui/button'

type BlogListCategoryButtonProps = {
  theme: 'dark' | 'light'
  sectionName: string
  path: string
  name: string
}

export const BlogListCategoryButton = (props: BlogListCategoryButtonProps) => {
  const { theme, sectionName, path, name } = props
  const currentPath = usePathname()
  const active = currentPath === path

  return (
    <Button
      size="xxs"
      appearance="solid"
      sectionName={sectionName}
      title={name}
      color={active ? 'acqua' : theme === 'dark' ? 'gray' : 'grayDark'}
      href={path}
    >
      {name}
    </Button>
  )
}

import { tv, VariantProps } from '@/utils/tv'

export const swiperNav = tv({
  slots: {
    base: ['text-left gap-x-3']
  },
  variants: {
    theme: {
      dark: {},
      light: {}
    }
  },
  defaultVariants: {
    theme: 'dark'
  }
})

export type SwiperNavVariantProps = VariantProps<typeof swiperNav>

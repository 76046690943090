'use client'

import { useState, useCallback, useEffect } from 'react'

const useInfiniteScroll = <T>(items: T[] = [], itemsPerLoad = 6, ms = 100) => {
  const [loading, setLoading] = useState(false)
  const [hasMoreItems, setHasMoreItems] = useState(true)
  const [loadedItems, setLoadedItems] = useState<T[]>([])

  useEffect(() => {
    const firstItems = items.slice(0, itemsPerLoad + 1)
    setLoadedItems(firstItems)

    return () => {
      setLoadedItems([])
    }
  }, [items, itemsPerLoad])

  const getMoreItems = useCallback(() => {
    if (!hasMoreItems) return

    if (!loading) {
      setLoading(true)

      setTimeout(() => {
        setLoadedItems((prevItems) => {
          const nextItems = items.slice(
            prevItems.length,
            prevItems.length + itemsPerLoad
          )

          return [...prevItems, ...nextItems]
        })

        setLoading(false)
      }, ms)
    }
  }, [loading, items, itemsPerLoad, hasMoreItems, ms])

  useEffect(() => {
    setHasMoreItems(loadedItems.length < items.length)
  }, [loadedItems, items])

  return {
    loading,
    hasMoreItems,
    loadedItems,
    getMoreItems
  }
}

export default useInfiniteScroll

import NextImage from 'next/image'
import { useAvatar, useAvatarProps } from './use-avatar'

export type AvatarProps = useAvatarProps

const Avatar = (props: AvatarProps) => {
  const { className, image, styles } = useAvatar(props)

  return (
    <div className={styles.base({ className })}>
      <NextImage
        className={styles.image()}
        src={image.url}
        width={image.width}
        height={image.height}
        alt={image.alternativeText}
      />
    </div>
  )
}

export default Avatar

import { isPreview } from '@/utils/env'
import { BlogPost } from '@/types'
import { strapiFetch } from '@/services/client'
import { Locale } from '@/config/locales'

const getPost = async (locale: Locale, slug: string) => {
  const params: Record<string, string | number> = {
    _locale: locale,
    slug
  }

  if (!isPreview) {
    params.status = 'published_date'
  }

  return await strapiFetch<BlogPost[]>({
    path: `blog-posts`,
    params,
    options: {
      next: {
        tags: [slug],
        revalidate: !isPreview ? 72000 : undefined // 20 hours
      }
    }
  })
}

export default getPost

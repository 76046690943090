import { BlogPosting, WithContext } from 'schema-dts'
import { BlogPost, ContentType, PageParamsProps } from '@/types'
import { getAllBlogCategories, getAllBlogPosts } from '@/services/blog'
import { formatDateToString } from './date'
import { baseUrl, siteName } from './env'

export const makePostCardData = (post: BlogPost) => {
  return {
    id: post.id,
    locale: post.locale,
    date: post.date,
    category: {
      ...post.category,
      slug: post.category.path
    },
    preTitle: post.category.name,
    title: post.title,
    description: post.description,
    url: post.path,
    cover: post.image,
    writer: post.writer
  }
}

export const hasBlogSection = (page: ContentType) => {
  return page.contentSections.some(({ __component }) =>
    __component.includes('blog')
  )
}

export const getBlogData = async (
  locale: PageParamsProps['locale'],
  category: PageParamsProps['category'],
  slug: PageParamsProps['slug']
) => {
  const isPostPage = !!category && !!slug && slug.length === 1
  const limit = isPostPage ? 7 : -1

  const [categories, posts] = await Promise.all([
    getAllBlogCategories(locale),
    getAllBlogPosts(locale, limit, category)
  ])

  return {
    categories,
    posts
  }
}

export const readTime = (content: string | null = '') => {
  if (!content) return ``

  const wordsPerMinute = 200
  const words = content.split(' ').length
  const readTime = Math.round(words / wordsPerMinute)

  return `${readTime} min`
}

export const getBlogPostSchema = (post: BlogPost): WithContext<BlogPosting> => {
  const datePublished = formatDateToString(post.date, post.locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })

  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    url: `${baseUrl}${post.path}`,
    datePublished,
    headline: post.title,
    description: post.description ?? undefined,
    publisher: {
      '@type': 'Organization',
      name: siteName,
      logo: {
        '@type': 'ImageObject',
        url: `${baseUrl}/moises-brand.png`
      }
    },
    author: {
      '@type': 'Person',
      name: post?.writer.name,
      image: post?.writer?.picture.url
    },
    image: post?.image.url,
    isAccessibleForFree: true
  }
}

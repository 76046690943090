'use client'

import { useEffect, useState } from 'react'
import { useWindowSize } from 'react-use'
import { SliderCard } from '@/types'
import { Video } from '../video'
import { SliderCardVariantProps } from './slider-card.variants'

export type SliderCardVideoProps = { className?: string } & Pick<
  SliderCard,
  'backgroundSize' | 'background'
> &
  Pick<SliderCardVariantProps, 'type'>

const SliderCardVideo = ({
  className = '',
  type,
  background,
  backgroundSize
}: SliderCardVideoProps) => {
  const [showVideo, setShowVideo] = useState(false)
  const { width } = useWindowSize()

  useEffect(() => {
    if (width >= 768 && type === 'video') {
      !showVideo && setShowVideo(true)
    } else {
      showVideo && setShowVideo(false)
    }
  }, [width, showVideo, type])

  if (type !== 'video' || !showVideo || !background) return null

  return (
    <Video
      size={backgroundSize === 'contain' ? 'video' : 'full'}
      className={className}
      src={background.url}
      mime={background.mime}
    />
  )
}

export default SliderCardVideo

import { useState, useMemo, useRef } from 'react'
import {
  TableOfContentsVariantProps,
  tableOfContents
} from './table-of-contents.variants'
import { VFileCompatible } from 'vfile'
import { fromMarkdown } from 'mdast-util-from-markdown'
import createToc, { type TocItem } from '../../markdown/plugins/toc-headings'

export type useTableOfContentsProps = TableOfContentsVariantProps & {
  theme?: 'dark' | 'light'
  className?: string
  source: VFileCompatible
  title: string
  subtitle: string
}

export const useTableOfContents = (props: useTableOfContentsProps) => {
  const [isTocOpen, setIsTocOpen] = useState(false)
  const { className = '', source, theme = 'dark', title, subtitle } = props
  const menuRef = useRef(null)

  const styles = useMemo(
    () => tableOfContents({ open: isTocOpen }),
    [isTocOpen]
  )

  const tree = fromMarkdown(source as string)
  const tocItems: TocItem[] = createToc(tree)

  const showToc = (tggl: boolean) => {
    setIsTocOpen(tggl)
  }

  const linkClick = () => {
    setIsTocOpen(false)
  }

  return {
    theme,
    className,
    styles,
    tocItems,
    menuRef,
    title,
    subtitle,
    isTocOpen,
    showToc,
    linkClick
  }
}

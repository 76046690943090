'use client'

import NextImage from 'next/image'
import YouTube from 'react-youtube'
import { Modal } from '../modal'
import { Icon } from '../icon'
import { useVideoCard, useVideoCardProps } from './use-video-card'

export type VideoCardProps = useVideoCardProps

const VideoCard = (props: VideoCardProps) => {
  const {
    className,
    title,
    cover,
    videoId,
    handleClick,
    modalState,
    setModalState,
    styles
  } = useVideoCard(props)

  return (
    <>
      <Modal active={modalState} handleClose={() => setModalState(false)}>
        <div className="p-4">
          <YouTube
            videoId={videoId}
            className={styles.video()}
            iframeClassName="rounded-xl"
            opts={{
              playerVars: {
                autoplay: 1
              }
            }}
          />
        </div>
      </Modal>
      <div
        className={styles.base({ className })}
        tabIndex={0}
        role="button"
        aria-label={title || cover?.alternativeText || 'Video'}
        aria-pressed={modalState}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            setModalState(true)
          }
        }}
      >
        <span className={styles.play()}>
          <Icon name="play" className={styles.icon()} />
        </span>
        {!!cover && (
          <NextImage
            fill
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            quality={95}
            src={cover.url}
            alt={cover?.alternativeText || ''}
            className={styles.image()}
          />
        )}
      </div>
    </>
  )
}

export default VideoCard

'use client'
import { RefObject, useEffect, useRef, useState } from 'react'
import { useInView } from 'framer-motion'

type ObserverProps = {
  ref: RefObject<Element>
  inView: boolean
}

const useActiveItemInView = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const observers = useRef<ObserverProps[]>([])

  const checkAndUpdateActiveIndex = () => {
    observers.current.forEach((observer, index) => {
      if (observer.inView) {
        setCurrentIndex(index)
      }
    })
  }

  const useItemObserver = (index: number): RefObject<Element> => {
    const itemRef = useRef<Element>(null)
    const inView = useInView(itemRef, { once: false, margin: '-40%' })

    useEffect(() => {
      observers.current[index] = {
        ref: itemRef,
        inView
      }

      checkAndUpdateActiveIndex()
    }, [inView, index])

    return itemRef
  }

  return {
    currentIndex,
    useItemObserver
  }
}

export default useActiveItemInView
